/* eslint-disable no-continue */

import moment from 'moment'
import { StaffSchedulingType } from '../enums'

export const _ = undefined

export function checkIsAbsent(context, entry, dragContext) {
  if (!dragContext || dragContext.type !== StaffSchedulingType.Employee) {
    return false
  }

  const employee = dragContext.value.id

  for (let i = 0; i < this.absences.length; i += 1) {
    const absence = this.absences[i]
    if (absence.employee !== employee) continue

    const isBetween = moment(context.date, 'YYYY-MM-DD').isBetween(
      absence.startDate,
      absence.endDate,
      'day',
      '[]',
    )

    if (isBetween) {
      return true
    }
  }

  return false
}
