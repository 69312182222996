var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.config)?_c('div',[(_vm.activeLayerPopulated)?_c('Calendar',{attrs:{"date":_vm.date,"calendar":_vm.calendar},scopedSlots:_vm._u([{key:"frontRow",fn:function(ref){
var rowIndex = ref.rowIndex;
return [(_vm.config.layers[rowIndex])?_c('div',[_vm._v(" "+_vm._s(_vm.config.layers[rowIndex].name)+" ")]):_vm._e(),(_vm.config.layers[rowIndex])?_c('div',[_vm._v(" "+_vm._s(_vm.config.layers[rowIndex].startTime)+" - "+_vm._s(_vm.config.layers[rowIndex].endTime)+" ")]):_vm._e()]}},{key:"default",fn:function(ref){
var context = ref.context;
return [(
          context.indexConfig.groupIndex !== null &&
          _vm.config.groups[context.indexConfig.groupIndex]
        )?_c('div',{staticClass:"smaller-font"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            _vm.config.groups[context.indexConfig.groupIndex].name
          ),expression:"\n            config.groups[context.indexConfig.groupIndex].name\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"status-bar",style:({
            backgroundColor:
              _vm.config.groups[context.indexConfig.groupIndex].color,
          })},[_c('Orders',{attrs:{"config":_vm.config,"orders-of-line":context.orders,"context":context}})],1),_c('div',{staticClass:"entries"},_vm._l((context.value),function(entry){
          var _obj, _obj$1;
return _c('div',{key:entry.insertedIndex},[(!_vm.isPast(entry.date))?_c('div',{staticClass:"d-flex transition-highlight",class:( _obj = {
                highlight: entry.id === _vm.highlight
              }, _obj[_vm.getAttendance(context, entry)] = true, _obj['is-sick'] = entry.isSick, _obj ),on:{"contextmenu":function (e) { return _vm.openContextMenu(e, {
                    context: context,
                    entry: entry,
                  }); }}},[_c('div',{staticClass:"icon-wrapper"},[_c('Status',{attrs:{"status":_vm.displayStatus(context, entry)}}),_c('Note',{attrs:{"active":_vm.findActiveValues(context, entry),"is-admin":_vm.isAdmin,"context":context,"entry":entry},on:{"change-note":_vm.changeNote}})],1),_c('span',{staticClass:"user-select-none count"},[_vm._v(" "+_vm._s(entry.insertedIndex + 1)+". ")]),(_vm.dragContext === null)?_c('span',{staticClass:"drag-zone-empty"},[_c('SearchHighlight',{attrs:{"tooltip":_vm.getEmployeeTooltip(context, entry),"content":_vm.displayContent(context, entry),"search-term":_vm.searchTerm,"small":_vm.displayServiceProvider(context, entry)}})],1):(_vm.checkBlocked(context, entry, _vm.dragContext))?_c('span',{staticClass:"drag-zone blocked"},[_c('SearchHighlight',{attrs:{"tooltip":_vm.getEmployeeTooltip(context, entry),"content":_vm.displayContent(context, entry),"search-term":_vm.searchTerm,"small":_vm.displayServiceProvider(context, entry)}})],1):(_vm.checkIsAbsent(context, entry, _vm.dragContext))?_c('span',{staticClass:"drag-zone blocked-absent"},[_c('SearchHighlight',{attrs:{"tooltip":_vm.getEmployeeTooltip(context, entry),"content":_vm.displayContent(context, entry),"search-term":_vm.searchTerm,"small":_vm.displayServiceProvider(context, entry)}})],1):_c('span',{class:("drag-zone " + (_vm.getAvailabilityCssClass(context))),on:{"drop":function () { return _vm.drop(context, entry); },"dragover":_vm.allowDrop}},[_c('SearchHighlight',{attrs:{"tooltip":_vm.getEmployeeTooltip(context, entry),"content":_vm.displayContent(context, entry),"search-term":_vm.searchTerm,"small":_vm.displayServiceProvider(context, entry)}})],1)]):_c('div',{staticClass:"d-flex transition-highlight",class:( _obj$1 = {
                highlight: entry.id === _vm.highlight
              }, _obj$1[_vm.getAttendance(context, entry)] = true, _obj$1['is-sick'] = entry.isSick, _obj$1 ),on:{"contextmenu":function($event){return _vm.openPastContextMenu($event, context, entry)}}},[_c('div',{staticClass:"icon-wrapper"},[_c('Status',{attrs:{"status":_vm.displayStatus(context, entry)}}),_c('Note',{attrs:{"active":_vm.findActiveValues(context, entry),"context":context,"entry":entry,"is-admin":_vm.isAdmin},on:{"change-note":_vm.changeNote}})],1),_c('span',{staticClass:"user-select-none count"},[_vm._v(" "+_vm._s(entry.insertedIndex + 1)+". ")]),_c('span',{staticClass:"drag-zone-empty"},[_c('SearchHighlight',{attrs:{"tooltip":_vm.getEmployeeTooltip(context, entry),"content":_vm.displayContent(context, entry),"search-term":_vm.searchTerm,"small":_vm.displayServiceProvider(context, entry)}})],1)])])}),0)]):_c('div')]}}],null,false,1518504038)}):_c('div',{staticClass:"text-center mt-1"},[_vm._v(" "+_vm._s(_vm.$t('StaffScheduling.NoLayerSelected'))+" ")]),_c('ContextMenu',{ref:"context-menu",attrs:{"is-admin":_vm.isAdmin,"config":_vm.config},on:{"remove":_vm.remove,"change-name":_vm.showServiceProviderName,"update-attendance":_vm.updateAttendance,"update-note":_vm.openNoteModal,"handle-status":_vm.handleStatus,"change-end-time":_vm.changeEndTime,"change-start-time":_vm.changeStartTime}}),_c('PastContextMenu',{ref:"past-context-menu",attrs:{"is-admin":_vm.isAdmin},on:{"update-attendance":_vm.updateAttendance,"update-note":_vm.openNoteModal,"change-end-time":_vm.changeEndTime,"change-start-time":_vm.changeStartTime}}),_c('UpdateNoteModal',{ref:"update-note-modal",attrs:{"config":_vm.config,"is-admin":_vm.isAdmin},on:{"change-note":_vm.changeNote}}),(_vm.isAdmin)?_c('ServiceProviderName',{ref:"service-provider-name",attrs:{"service-providers":_vm.serviceProviders},on:{"change-name":_vm.changeServiceProviderName}}):_vm._e(),_c('ChangeTimeModal',{ref:"change-time-modal",attrs:{"config":_vm.config},on:{"change-time":_vm.changeTime}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }