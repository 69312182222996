<template>
  <b-modal
    v-if="active && entry && context"
    :visible.sync="showModal"
    size="lg"
    :title="$t('ManageShifts.Note')"
    :ok-title="$t('Global.Ok')"
    :cancel-title="$t('Global.Retreat')"
    cancel-variant="outline-secondary"
    :ok-disabled="isDisabled"
    @ok="handleOk"
    @hide="close"
    @cancel="close"
  >
    <b-form-textarea
      v-model="note"
      rows="18"
      no-resize
      :disabled="isDisabled"
    />
  </b-modal>
</template>

<script>
import { BFormTextarea, BModal } from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    BModal,
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    showModal: false,
    note: '',

    active: null,
    entry: null,
    context: null,
  }),

  computed: {
    isDisabled() {
      return !this.isAdmin && !this.config.canManagePastAttendance
    },
  },

  methods: {
    show({ active, entry, context }) {
      this.showModal = true
      this.active = active
      this.entry = entry
      this.context = context
      this.note = active.note
    },

    close() {
      this.showModal = false
      this.active = null
      this.entry = null
      this.context = null
    },

    handleOk() {
      this.$emit('change-note', {
        context: {
          active: this.active,
          context: this.context,
          entry: this.entry,
        },
        note: this.note,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.note-icon {
  align-self: center;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  cursor: pointer;
}
</style>
