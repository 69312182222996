<template>
  <div>
    <Calendar
      v-if="activeLayerPopulated"
      :active-layer-populated="activeLayerPopulated"
      :date="date"
      :calendar="calendar"
      :active-layer-index="activeLayerIndex"
      @change-date="$emit('change-date', $event)"
      @change-layer-index="$emit('change-layer-index', $event)"
    >
      <template #frontRow="{ rowIndex }">
        <div v-if="config.layers[rowIndex]">
          {{ config.layers[rowIndex].name }}
        </div>
      </template>

      <template v-slot="{ context }">
        <div class="content-layout">
          <div
            v-if="
              context.indexConfig.groupIndex !== null &&
              config.groups[context.indexConfig.groupIndex]
            "
          >
            <div
              class="status-bar"
              :style="{
                backgroundColor:
                  config.groups[context.indexConfig.groupIndex].color,
                color: getContrastColor(
                  config.groups[context.indexConfig.groupIndex].color,
                ),
              }"
            >
              {{ config.groups[context.indexConfig.groupIndex].name }}
            </div>
            <div id="topContent" class="top-content-layout">
              <div v-if="context.value.length > 0">
                <div v-for="elem in context.value" :key="elem.insertedIndex">
                  <div class="assign-employee">
                    <div class="content">
                      <div class="status-display">
                        <Status :status="displayStatus(context, elem)" />
                      </div>
                      <div class="inserted-index">
                        {{ elem.insertedIndex + 1 + '.' }}
                      </div>
                      <div class="employee">
                        <div
                          v-if="findActiveValues(context, elem).type === null"
                          class="icon"
                        >
                          <feather-icon
                            v-if="isAdmin"
                            class="status-icon"
                            icon="PlusCircleIcon"
                            @click="handleUpdateDragContext(elem, context)"
                          />
                        </div>
                        <div v-else class="employee-content">
                          <span>{{ display(context, elem) }}</span>
                          <span class="service-provider-name">
                            {{ displayServiceProvider(context, elem) }}
                          </span>
                        </div>
                      </div>
                      <div v-if="isAdmin" class="change-status">
                        <div
                          v-if="findActiveValues(context, elem).type != null"
                          class="menu-item"
                        >
                          <div class="drop-down-container" tabindex="-1">
                            <div class="three-dots" />
                            <div v-if="!isPast()">
                              <div class="drop-down">
                                <ContextMenu
                                  :elem="elem"
                                  :context="context"
                                  :active="findActiveValues(context, elem)"
                                  :status="displayStatus(context, elem)"
                                  @remove="remove"
                                  @change-name="showServiceProviderName"
                                  @handle-status="handleStatus"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isAdmin" class="bottom-content-layout">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-4"
              :disabled="!canUpdate"
              @click="$emit('update-calendar')"
            >
              {{ $t('Global.Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="$emit('transmit')"
            >
              {{ $t('StaffScheduling.Transmit') }}
            </b-button>
          </div>
        </div>
        <PopUp
          v-if="activatePopUp"
          :layers="layers"
          :current-context="currentContext"
          :service-providers="serviceProviders"
          :employees="employees"
          :can-update="canUpdate"
          :search-term="searchTerm"
          :work-time-adjustments="workTimeAdjustments"
          :availability="availability"
          :active-layer="activeLayer"
          @action-click-start="$emit('action-click-start', $event)"
          @change-active-layer="$emit('change-active-layer', $event)"
          @update-calendar="$emit('updateCalendar', $event)"
          @fill-rest="$emit('fillRest', $event)"
          @close-popup="closePopUpActivity"
          @confirm-changes="confirmChanges()"
          @change-search-term="$emit('change-search-term', $event)"
        />

        <ServiceProviderName
          ref="service-provider-name"
          :service-providers="serviceProviders"
          @change-name="changeServiceProviderName"
        />
      </template>
    </Calendar>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import moment from 'moment'
import Calendar from './calendar.vue'
import Status from '../../content/status.vue'
import ServiceProviderName from './service-provider-name.vue'
import ContextMenu from './contextMenu.vue'
import { StaffSchedulingType, StaffSchedulingStatus } from '../../../enums'

import {
  handleDrop,
  checkAvailability,
  displayContent,
  display,
  findIndexInCreatePool,
  adjustmentWorkTime,
  dropCheck,
  checkRestTime,
  checkRestTimeSync,
  displayStatus,
  remove,
  insertContent,
  handleStatus,
  findActiveValues,
  fillRest,
  changeServiceProviderName,
} from '../../../utility/handleDragDrop'
import PopUp from './popUp/popUp.vue'

export default {
  components: {
    BButton,
    PopUp,
    ServiceProviderName,
    Calendar,
    Status,
    ContextMenu,
  },

  props: {
    activeLayerIndex: {
      type: Number,
      required: true,
    },
    activeLayer: {
      type: String,
      default: () => null,
    },
    layers: {
      type: Array,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      required: true,
    },
    dragContext: {
      type: Object,
      default: () => null,
    },
    date: {
      type: Number,
      required: true,
    },
    calendar: {
      type: Array,
      required: true,
    },
    availability: {
      type: Array,
      default: () => null,
    },
    serviceProviders: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    activeLayerPopulated: {
      type: Object,
      default: () => null,
    },
    calendarCache: {
      type: Object,
      required: true,
    },
    createPool: {
      type: Array,
      required: true,
    },
    updatePool: {
      type: Array,
      required: true,
    },
    deletePool: {
      type: Array,
      required: true,
    },
    workTimeAdjustments: {
      type: Object,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      activatePopUp: false,
      currentItem: null,
      currentContext: null,
    }
  },

  computed: {
    config() {
      return this.activeLayerPopulated.configuration
    },
    checkAvailability() {
      return (...args) => checkAvailability.call(this, ...args)
    },
    displayContent() {
      return (...args) => displayContent.call(this, ...args)
    },
  },

  methods: {
    getContrastColor(color) {
      color = color.replace('#', '')
      const r = parseInt(color.substring(0, 2), 16) / 255
      const g = parseInt(color.substring(2, 4), 16) / 255
      const b = parseInt(color.substring(4, 6), 16) / 255
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
      return luminance > 0.8 ? '#000000' : '#FFFFFF'
    },
    displayServiceProvider(context, elem) {
      const active = this.findActiveValues(context, elem)
      if (
        active.type !== StaffSchedulingType.ServiceProvider ||
        !active.serviceProviderName
      ) {
        return null
      }

      return this.serviceProviders.find(x => x.id === active.serviceProvider)
        .name
    },

    showServiceProviderName(context) {
      this.$refs['service-provider-name'].show(context)
    },

    handleUpdateDragContext(currentItem, updatedContext) {
      if (this.isPast()) {
        return
      }
      this.currentItem = currentItem
      this.currentContext = updatedContext
      this.activatePopUp = true
      this.$emit('force-update')
    },

    isPast() {
      const today = moment().startOf('day')
      const dateToCheck = moment(this.date).format('YYYY-MM-DD')
      return today.isAfter(dateToCheck)
    },

    closePopUpActivity() {
      this.currentItem = null
      this.currentContext = null
      this.activatePopUp = false
    },

    confirmChanges() {
      const context = {
        date: this.currentContext.date,
        indexConfig: this.currentContext.indexConfig,
        value: this.currentContext.value,
      }
      const entry = this.currentItem
      this.drop(context, entry)
      this.currentItem = null
      this.currentContext = null
      this.activatePopUp = false
      this.$emit('reset-drag-context')
    },

    openContextMenu({ context, entry, status, state }) {
      const active = this.findActiveValues(context, entry)
      if (active === null || active.type === null) return
      if (active.status === StaffSchedulingStatus.approved) return
      this.$refs['context-menu'].open({ context, entry, status, state })
    },

    checkBlocked(context, entry) {
      const restTime = this.checkRestTimeSync(context)
      const status = this.displayStatus(context, entry)
      const statusBlock =
        status === StaffSchedulingStatus.transmitted ||
        status === StaffSchedulingStatus.approved

      return restTime || statusBlock
    },

    allowDrop(event) {
      event.preventDefault()
    },

    displayStatus(...args) {
      return displayStatus.call(this, ...args)
    },

    handleStatus(...args) {
      return handleStatus.call(this, ...args)
    },

    findActiveValues(...args) {
      return findActiveValues.call(this, ...args)
    },

    insertContent(...args) {
      return insertContent.call(this, ...args)
    },

    remove(...args) {
      return remove.call(this, ...args)
    },

    drop(...args) {
      return handleDrop.call(this, ...args)
    },

    async checkRestTime(...args) {
      return checkRestTime.call(this, ...args)
    },

    checkRestTimeSync(...args) {
      return checkRestTimeSync.call(this, ...args)
    },

    fillRest(...args) {
      return fillRest.call(this, ...args)
    },

    changeServiceProviderName(...args) {
      return changeServiceProviderName.call(this, ...args)
    },

    display(...args) {
      return display.call(this, ...args)
    },

    findIndexInCreatePool(...args) {
      return findIndexInCreatePool.call(this, ...args)
    },

    adjustmentWorkTime(...args) {
      return adjustmentWorkTime.call(this, ...args)
    },

    async dropCheck(...args) {
      return dropCheck.call(this, ...args)
    },
  },
}
</script>

<style lang="scss" scoped>
.content-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .status-bar {
    border-top-left-radius: 10px 13px;
    border-top-right-radius: 10px 13px;
    width: 90vw;
    height: 3.5vh;
    font-size: 1.9vh;
    font-weight: 200;
    padding: 0.5vh;
  }
  .top-content-layout {
    height: 55vh;
    width: 90vw;
    background-color: #fff;
    overflow: auto;
    scrollbar-width: 50;
    scrollbar-width: 1.5vw;
    .assign-employee {
      width: 100%;
      height: 6vh;
      margin-bottom: 1.5vh;
      margin-top: 1.5vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .content {
        width: 90%;
        height: 100%;
        border-radius: 9px;
        background-color: rgba(0, 0, 0, 0.045);
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: center;
        align-items: center;

        .status-display {
          text-align: center;
          flex-basis: 10%;
        }
        .inserted-index {
          text-align: center;
          flex-basis: 5%;
        }

        .employee {
          text-align: left;
          flex-basis: 75%;
          align-items: center;
          height: 80%;
          display: flex;
          flex-direction: row;
          .icon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            .status-icon {
              width: 17%;
              height: 65%;
            }
          }
        }

        .change-status {
          display: flex;
          text-align: center;
          flex-basis: 10%;
          justify-content: center;
          .menu-item {
            color: #fcc;
            padding: 3px;
          }
          .three-dots:after {
            cursor: pointer;
            color: #444;
            content: '\2807';
            font-size: 20px;
            padding: 0 5px;
          }

          a {
            text-decoration: none;
            color: white;
          }

          a div {
            padding: 2px;
          }

          .drop-down {
            position: absolute;
            right: 5vw;
            background-color: white;
            color: black;
            border: 0.5px solid black;
            z-index: -1;
            margin-top: -2.8vh;
          }

          .drop-down-container:focus {
            outline: none;
          }

          .drop-down-container:focus .drop-down {
            opacity: 1;
            z-index: 100;
            max-height: 100vh;
            transition:
              opacity 0.2s,
              z-index 0.2s,
              max-height 0.2s;
          }
        }
      }
    }
  }
  .top-content-layout::-webkit-scrollbar {
    width: 1.5vw;
  }

  .bottom-content-layout {
    color: rgba(255, 255, 255, 0.5);
    background-color: white;
    width: 90vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    align-items: center;
  }
}

.service-provider-name {
  font-size: 11px;
}
.employee-content {
  display: flex;
  flex-direction: column;
}
</style>
