<template>
  <div class="side-container">
    <div class="side-content">
      <collapse>
        <collapse-item
          :title="$t('StaffScheduling.Layers')"
          :is-visible="true"
          class="collapse-item-staff-scheduling"
        >
          <Layers
            :search-results="searchResults"
            :search-term="searchTerm"
            :layers="layers"
            :active-layer="activeLayer"
            @change-active-layer="$emit('change-active-layer', $event)"
          />
        </collapse-item>
        <collapse-item
          v-if="isAdmin"
          :title="$t('StaffScheduling.ServiceProviders')"
          :is-visible="true"
          class="collapse-item-staff-scheduling"
        >
          <ServiceProviders
            :is-admin="isAdmin"
            :date="date"
            :search-term="searchTerm"
            :service-providers="serviceProviders"
            :active-layer="activeLayer"
            @action-drag-start="$emit('action-drag-start', $event)"
            @action-drag-end="$emit('action-drag-end')"
            @fill-rest="$emit('fill-rest', $event)"
          />
        </collapse-item>
        <collapse-item
          v-if="isAdmin"
          :title="$t('StaffScheduling.Employees')"
          :is-visible="true"
          class="employees-collapse-item collapse-item-staff-scheduling"
        >
          <Employees
            :is-admin="isAdmin"
            :search-term="searchTerm"
            :work-time-adjustments="workTimeAdjustments"
            :employees="employees"
            :active-layer="activeLayer"
            :availability="availability"
            :desired-count="desiredCount"
            @action-drag-start="$emit('action-drag-start', $event)"
            @action-drag-end="$emit('action-drag-end')"
          />
        </collapse-item>
      </collapse>
    </div>
    <div v-if="isAdmin || canManagePastAttendance" class="side-bottom">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :disabled="!canUpdate"
        @click="$emit('update-calendar')"
      >
        {{ $t('Global.Save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Collapse from '@core/components/app-collapse/AppCollapse.vue'
import CollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import Layers from './layers.vue'
import Employees from './employees.vue'
import ServiceProviders from './service-providers.vue'

export default {
  components: {
    Collapse,
    CollapseItem,
    BButton,
    Layers,
    Employees,
    ServiceProviders,
  },

  props: {
    date: {
      type: Number,
      required: true,
    },
    activeLayer: {
      type: String,
      default: () => null,
    },
    employees: {
      type: Array,
      required: true,
    },
    serviceProviders: {
      type: Array,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
    availability: {
      type: Array,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      required: true,
    },
    workTimeAdjustments: {
      type: Object,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    searchResults: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    canManagePastAttendance: {
      type: Boolean,
      required: true,
    },
    desiredCount: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.side-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .side-content {
    flex: 1;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    padding-top: 2rem;
  }

  .side-bottom {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}
</style>

<style lang="scss">
.collapse-item-staff-scheduling {
  .card-header {
    padding: 0.5rem 2.8rem 0.5rem 1rem !important;
    .collapse-title {
      font-size: 14px;
    }
  }
}
.employees-collapse-item {
  .card-body {
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
