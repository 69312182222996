<template>
  <div class="modal">
    <b-modal
      id="modal"
      ref="my-modal"
      title="Name"
      :centered="true"
      :ok-title="$t('Global.Ok')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Global.Retreat')"
      @ok="handleSubmit"
    >
      <b-form-input id="name-input" v-model="name" autocomplete="off" />
    </b-modal>
  </div>
</template>

<script>
import { BFormInput, BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormInput,
  },

  props: {
    serviceProviders: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    context: null,
    name: '',
  }),

  computed: {
    serviceProvider() {
      const id = this.context.active.serviceProvider
      const serviceProvider = this.serviceProviders.find(x => x.id === id)
      return serviceProvider.name
    },
  },

  methods: {
    handleSubmit() {
      if (this.name === null) return
      if (this.name.length === 0) return
      this.$emit('change-name', {
        context: this.context.context,
        value: this.name,
        entry: this.context.entry,
      })
    },

    show(context) {
      this.context = context
      this.name = context.active.serviceProviderName
      this.$bvModal.show('modal')
    },

    onShown() {
      this.focusRef(this.$refs.name)
    },

    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus()
        })
      })
    },
  },
}
</script>
