<template>
  <vue-context ref="menu">
    <li v-if="show.showName">
      <b-link
        class="d-flex align-items-center"
        @click="handleAction('change-name')"
      >
        <feather-icon icon="ChevronsRightIcon" size="16" />
        <span class="ml-75">{{ $t('StaffScheduling.ChangeName') }}</span>
      </b-link>
    </li>
    <li v-if="show.showTransmit">
      <b-link
        class="d-flex align-items-center"
        @click="handleStatus('transmitted')"
      >
        <feather-icon icon="ChevronsRightIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.StatusAction.transmitted') }}
        </span>
      </b-link>
    </li>
    <li v-if="show.showApprove">
      <b-link
        class="d-flex align-items-center"
        @click="handleStatus('approved')"
      >
        <feather-icon icon="UserCheckIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.StatusAction.approved') }}
        </span>
      </b-link>
    </li>
    <li v-if="show.showCancel">
      <b-link
        class="d-flex align-items-center"
        @click="handleStatus('canceled')"
      >
        <feather-icon icon="XIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.StatusAction.canceled') }}
        </span>
      </b-link>
    </li>
    <li v-if="show.showReject">
      <b-link
        class="d-flex align-items-center"
        @click="handleStatus('rejected')"
      >
        <feather-icon icon="UserXIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.StatusAction.rejected') }}
        </span>
      </b-link>
    </li>
    <li v-if="show.showRemove">
      <b-link class="d-flex align-items-center" @click="handleAction('remove')">
        <feather-icon icon="Trash2Icon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.StatusAction.deleted') }}
        </span>
      </b-link>
    </li>
    <li v-if="show.showCancellationNoted">
      <b-link
        class="d-flex align-items-center"
        @click="handleStatus('cancellationNoted')"
      >
        <feather-icon icon="SlashIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.StatusAction.cancellationNoted') }}
        </span>
      </b-link>
    </li>
    <hr
      v-if="showDivider()"
      style="margin-bottom: 0.5rem; margin-top: 0.5rem"
    />
    <li v-if="show.showAttendanceAvailable">
      <b-link
        class="d-flex align-items-center"
        @click="updateAttendance(StaffSchedulingAttendance.available)"
      >
        <feather-icon icon="UserXIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.ChangeAttendanceToAvailable') }}
        </span>
      </b-link>
    </li>
    <li v-if="show.showAttendanceAbsent">
      <b-link
        class="d-flex align-items-center"
        @click="updateAttendance(StaffSchedulingAttendance.absent)"
      >
        <feather-icon icon="UserCheckIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.ChangeAttendanceToAbsent') }}
        </span>
      </b-link>
    </li>
    <li v-if="show.showChangeStartTime">
      <b-link
        class="d-flex align-items-center"
        @click="handleAction('change-start-time')"
      >
        <feather-icon icon="ClockIcon" size="16" />
        <span class="ml-75">{{ $t('StaffScheduling.ChangeStartTime') }}</span>
      </b-link>
    </li>
    <li v-if="show.showChangeEndTime">
      <b-link
        class="d-flex align-items-center"
        @click="handleAction('change-end-time')"
      >
        <feather-icon icon="ClockIcon" size="16" />
        <span class="ml-75">{{ $t('StaffScheduling.ChangeEndTime') }}</span>
      </b-link>
    </li>
    <li v-if="show.showChangeNote">
      <b-link class="d-flex align-items-center" @click="updateNote">
        <feather-icon icon="MessageSquareIcon" size="16" />
        <span class="ml-75">{{ $t('StaffScheduling.ChangeNote') }}</span>
      </b-link>
    </li>
  </vue-context>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import VueContext from 'vue-context'
import moment from 'moment'

import StaffSchedulingAttendance from '@/views/loggedin/manage-shifts/enums'
import { StaffSchedulingStatus, StaffSchedulingType } from '../../enums'

export default {
  components: {
    VueContext,
    BLink,
  },

  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },

    config: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    context: null,
    StaffSchedulingAttendance,
  }),

  computed: {
    show() {
      if (!this.context) return {}

      const { status, type, attendance } = this.context.active

      const hasShiftStarted = this.hasShiftStarted()

      if (this.isAdmin === false) {
        return {
          showAttendanceAvailable:
            hasShiftStarted &&
            status === StaffSchedulingStatus.approved &&
            attendance !== StaffSchedulingAttendance.available,
          showAttendanceAbsent:
            hasShiftStarted &&
            status === StaffSchedulingStatus.approved &&
            attendance !== StaffSchedulingAttendance.absent,
          showChangeNote:
            hasShiftStarted && status === StaffSchedulingStatus.approved,
          showChangeStartTime:
            hasShiftStarted && status === StaffSchedulingStatus.approved,
          showChangeEndTime:
            hasShiftStarted && status === StaffSchedulingStatus.approved,
        }
      }

      return {
        showName:
          type === StaffSchedulingType.ServiceProvider &&
          (status === StaffSchedulingStatus.open ||
            status === StaffSchedulingStatus.transmitted ||
            (status === StaffSchedulingStatus.approved && this.isAdmin)),
        showTransmit: status === StaffSchedulingStatus.open,
        showApprove: status === StaffSchedulingStatus.transmitted,
        showReject: status === StaffSchedulingStatus.transmitted,
        showCancel: status === StaffSchedulingStatus.approved,
        showRemove:
          status === StaffSchedulingStatus.open ||
          status === StaffSchedulingStatus.rejected ||
          status === StaffSchedulingStatus.cancellationNoted,
        showCancellationNoted: status === StaffSchedulingStatus.canceled,

        showAttendanceAvailable:
          hasShiftStarted &&
          status === StaffSchedulingStatus.approved &&
          attendance !== StaffSchedulingAttendance.available,
        showAttendanceAbsent:
          hasShiftStarted &&
          status === StaffSchedulingStatus.approved &&
          attendance !== StaffSchedulingAttendance.absent,
        showChangeNote:
          hasShiftStarted && status === StaffSchedulingStatus.approved,
        showChangeStartTime:
          hasShiftStarted && status === StaffSchedulingStatus.approved,
        showChangeEndTime:
          hasShiftStarted && status === StaffSchedulingStatus.approved,
      }
    },
  },

  methods: {
    open(event, value) {
      this.context = value
      this.$refs.menu.open(event)

      this.$nextTick(() => {
        const showSomeElements = Object.values(this.show).some(Boolean)
        if (!showSomeElements) this.close()
      })
    },

    close() {
      this.$refs.menu.close()
    },

    showDivider() {
      const someBottomElements =
        this.show.showAttendanceAvailable ||
        this.show.showAttendanceAbsent ||
        this.show.showChangeNote ||
        this.show.showChangeStartTime ||
        this.show.showChangeEndTime

      const someTopElements =
        this.show.showName ||
        this.show.showTransmit ||
        this.show.showApprove ||
        this.show.showCancel ||
        this.show.showReject ||
        this.show.showRemove ||
        this.show.showCancellationNoted

      return someBottomElements && someTopElements
    },

    handleAction(action) {
      this.$emit(action, this.context)
    },

    handleStatus(status) {
      this.$emit('handle-status', { context: this.context, status })
    },

    updateAttendance(attendance) {
      this.$emit('update-attendance', { context: this.context, attendance })
    },

    updateNote() {
      this.$emit('update-note', this.context)
    },

    hasShiftStarted() {
      const { date } = this.context.active
      const { layerIndex } = this.context.context.indexConfig
      const { startTime } = this.config.layers[layerIndex]

      const shiftStart = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm:ss')
      const now = moment()

      return now.isAfter(shiftStart)
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/libs/vue-context.scss';
</style>
