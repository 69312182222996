<template>
  <div class="calendar">
    <div class="center-horizontal">
      <div
        v-for="(row, rowIndex) in calendar"
        :key="`calendar-row-${rowIndex}`"
      >
        <div
          class="layers"
          :class="{ 'active-layer': rowIndex === activeLayerIndex }"
          @click="changeLayerIndex(rowIndex)"
        >
          <slot name="frontRow" :row-index="rowIndex" />
        </div>
      </div>
    </div>
    <div class="calender-horizontal">
      <div
        v-for="moment in weekSpan"
        :key="moment.valueOf()"
        class="calendar-header-item"
      >
        <div class="week-items">
          <div class="week-item">
            {{ $t(`Dates.Week.Short.${moment.locale('en').format('dddd')}`) }}
          </div>
          <div class="days-layer">
            <div
              :class="{ changeColor: checkDate(moment) }"
              @click="changeDate(moment)"
            >
              {{ moment.format('DD') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isPastStartDate">
      <div
        v-for="(row, rowIndex) in calendar"
        :key="`calendar-row-${rowIndex}`"
      >
        <div v-if="rowIndex === activeLayerIndex">
          <div v-for="(dateItem, index) in row" :key="`calendar-row-${index}`">
            <div v-if="isCurrentContext(dateItem)">
              <slot :context="dateItem" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t('StaffScheduling.NoLayer') }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    activeLayerPopulated: {
      type: Object,
      required: true,
    },
    date: {
      type: Number,
      required: true,
    },
    calendar: {
      type: Array,
      required: true,
    },
    activeLayerIndex: {
      type: Number,
      required: true,
    },
  },

  computed: {
    isPastStartDate() {
      const date = moment(this.date)
      const startDate = moment(
        this.activeLayerPopulated.configuration.startDate,
      )
      return date.isSameOrAfter(startDate)
    },
    weekSpan() {
      const date = moment(this.date)
      const weekStart = date.clone().startOf('week')
      const days = []
      for (let i = 0; i <= 6; i += 1) {
        days.push(moment(weekStart).add(i, 'days'))
      }
      return days
    },
  },
  methods: {
    changeDate(value) {
      const timeStamp = value.valueOf()
      if (timeStamp === this.date) return
      this.$emit('change-date', timeStamp)
    },
    changeLayerIndex(rowIndex) {
      this.$emit('change-layer-index', rowIndex)
    },
    isCurrentContext(context) {
      const datumString = moment(this.date).format('YYYY-MM-DD')
      return context.date === datumString
    },
    checkCurrentDate(date) {
      return date === this.date
    },
    checkDate(weekday) {
      return (
        weekday.format('YYYY-MM-DD') === moment(this.date).format('YYYY-MM-DD')
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.calendar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .calender-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .calendar-header-item {
      justify-content: center;
      font-weight: 600;
      display: inline-block;
      align-items: center;
      color: black;

      .week-items {
        margin-bottom: 20px;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
        margin-left: 17px;
        margin-right: 17px;
        font-weight: 505;
        .week-item {
          font-size: 12px;
          font-weight: 300;
        }
      }
      .days-layer {
        color: black;
        .changeColor {
          color: orange;
        }
      }
    }
  }

  .center-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .layers {
      margin-left: 15px;
      margin-right: 15px;
      font-size: 15px;
      font-weight: 200;
      color: black;
    }
    .layers.active-layer {
      color: orange;
      text-decoration: underline;
      text-underline-position: under;
      text-underline-offset: 2px;
    }
  }
}
</style>
