<template>
  <b-modal
    v-model="visible"
    :title="
      $t('StaffScheduling.WorksheetServiceProvider.WorksheetServiceProvider')
    "
  >
    <b-input-group>
      <DatePicker
        ref="date-picker"
        v-model="dateRange"
        class="form-control"
        :config="{
          mode: 'range',
          dateFormat: 'd.m.Y',
          maxDate: yesterday.toDate(),
          defaultDate: [lastWeekStart.toDate(), lastWeekEnd.toDate()],
          onChange: handleChange,
        }"
      />
      <b-input-group-append
        is-text
        class="cursor-pointer"
        @click="openDatePicker"
      >
        <feather-icon icon="CalendarIcon" />
      </b-input-group-append>
    </b-input-group>

    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="close"
      >
        {{ $t('Global.Retreat') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="dispatch"
      >
        {{ $t('StaffScheduling.WorksheetServiceProvider.Dispatch') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="download"
      >
        {{ $t('StaffScheduling.WorksheetServiceProvider.Download') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BButton, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import moment from 'moment'
import DatePicker from 'vue-flatpickr-component'

const formatDate = date => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const formatDateDe = date => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${day}.${month}.${year}`
}

export default {
  components: { BButton, DatePicker, BInputGroup, BInputGroupAppend },

  data: () => ({
    visible: false,

    dateRange: null,

    startDate: null,
    endDate: null,
  }),

  computed: {
    yesterday() {
      return moment().subtract(1, 'day').endOf('day')
    },
    lastWeekStart() {
      return moment().subtract(1, 'week').startOf('week')
    },
    lastWeekEnd() {
      return moment().subtract(1, 'week').endOf('week')
    },

    startDateString() {
      return formatDate(this.startDate)
    },
    endDateString() {
      return formatDate(this.endDate)
    },
  },

  mounted() {
    this.startDate = this.lastWeekStart.toDate()
    this.endDate = this.lastWeekEnd.toDate()
  },

  methods: {
    open() {
      this.visible = true
    },

    openDatePicker() {
      const datePickerComponent = this.$refs['date-picker']

      if (!datePickerComponent.isOpen) {
        datePickerComponent.fp.open()
      }
    },

    handleChange(selectedDates) {
      [this.startDate] = selectedDates
      this.endDate = selectedDates[selectedDates.length === 2 ? 1 : 0]
    },

    async download() {
      try {
        const response = await this.$axios.get(
          `service-provider-time-sheet/download/${this.startDateString}/${this.endDateString}`,
          { responseType: 'blob' },
        )

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Stundenzettel_${this.startDateString.replace(
            /-/g,
            '',
          )}-${this.endDateString.replace(/-/g, '')}.zip`,
        )
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        if (error.request.status === 422) {
          this.$alert.invalid(
            this.$t(
              'StaffScheduling.WorksheetServiceProvider.NoEntriesAvailable',
              {
                startDate: formatDateDe(this.startDate),
                endDate: formatDateDe(this.endDate),
              },
            ),
          )
          return
        }

        this.$alert.error()
      }
      this.visible = false
    },
    async dispatch() {
      try {
        const { data } = await this.$axios.get(
          `service-provider-time-sheet/dispatch/${this.startDateString}/${this.endDateString}`,
        )

        const items = []

        data.forEach(bundle => {
          items.push(
            this.$createElement('li', {
              class: ['li-space-y'],

              domProps: {
                innerHTML: this.$t(
                  'StaffScheduling.WorksheetServiceProvider.MailIncludes',
                  {
                    name: bundle.serviceProvider.name,
                    count: bundle.count,
                  },
                ),
              },
            }),
          )
        })

        const list = this.$createElement('ol', {}, items)

        const wrapper = this.$createElement('div', {}, [
          this.$createElement(
            'h5',
            {
              class: 'mt-1',
            },
            this.$t(
              'StaffScheduling.WorksheetServiceProvider.SuccessivelySentTo',
            ),
          ),
          list,
        ])

        this.$bvModal.msgBoxOk([wrapper], {
          title: this.$t(
            'StaffScheduling.WorksheetServiceProvider.SuccessivelySent',
          ),
          size: 'sm',
          okVariant: 'outline-primary',
          okTitle: 'Ok',
          centered: true,
          hideHeaderClose: false,
          okOnly: true,
        })
      } catch (error) {
        if (error.request.status === 422) {
          this.$alert.invalid(
            this.$t(
              'StaffScheduling.WorksheetServiceProvider.NoEntriesAvailable',
              {
                startDate: formatDateDe(this.startDate),
                endDate: formatDateDe(this.endDate),
              },
            ),
          )
          return
        }
        this.$alert.error()
      }

      this.visible = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.li-space-y {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>
