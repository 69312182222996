<template>
  <div>
    <collapse-item
      v-for="contract in Object.keys(categorizeEmployeesByContract)"
      :key="contract"
      :title="$t(`Management.Employees.Contract.Types.${contract}`)"
    >
      <div class="employee-grid">
        <h6>{{ $t('StaffScheduling.Name') }}</h6>
        <h6 class="text-center">{{ $t('StaffScheduling.Shifts') }}</h6>
      </div>
      <div class="contract-scroll">
        <div
          v-for="(
            workTimeCollection, workTimeCollectionIndex
          ) in categorizeEmployeesByContract[contract]"
          :key="workTimeCollection.key"
          :class="{
            muted:
              availabilityGrouped(workTimeCollection.employee.id) === undefined,
          }"
        >
          <div class="employee-grid">
            <div @click="clicked(workTimeCollection.employee)">
              <div
                :class="{
                  'active-employee':
                    workTimeCollection.employee === activeElement,
                }"
              >
                {{ workTimeCollection.employee.firstname }}
                {{ workTimeCollection.employee.lastname }}
              </div>
            </div>
            <div
              class="text-center"
              :class="{
                [showClass(workTimeCollection.bundle, contract)]: true,
              }"
            >
              {{ getWorkTime(workTimeCollection.bundle) }} /
              {{ ContactLayersToWork[contract].value }}
            </div>
          </div>
          <div class="hr-wrapper">
            <div
              v-if="
                workTimeCollectionIndex + 1 !==
                categorizeEmployeesByContract[contract].length
              "
              class="hr"
            />
          </div>
        </div>
      </div>
    </collapse-item>
    <div v-if="employeesForLayer.length === 0">
      {{ $t('StaffScheduling.NoEmployees') }}
    </div>
  </div>
</template>

<script>
import { ContactLayersToWork } from '@/views/loggedin/management/employees/utility/contracts'

import { getWorkTime, showClass } from '../../../../utility/employees'
import { StaffSchedulingType } from '../../../../enums'
import CollapseItem from './contract-collapse.vue'

export default {
  components: {
    CollapseItem,
  },

  props: {
    activeElement: {
      type: Object,
      default: () => null,
    },
    currentItem: {
      type: Object,
      default: () => null,
    },
    employees: {
      type: Array,
      required: true,
    },
    activeLayer: {
      type: String,
      default: () => null,
    },
    workTimeAdjustments: {
      type: Object,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    availability: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    ContactLayersToWork,
  }),

  computed: {
    employeesForLayer() {
      return this.employees
        .filter(
          x =>
            this.activeLayer &&
            x.employee.active === true &&
            x.employee.layers.map(y => y.id).includes(this.activeLayer),
        )
        .filter(x => {
          if (!this.searchTerm) return true
          return `${x.employee.firstname} ${x.employee.lastname}`.includes(
            this.searchTerm,
          )
        })
    },

    categorizeEmployeesByContract() {
      const contracts = {}

      this.employeesForLayer.forEach(workTimeCollection => {
        workTimeCollection.workTimeBundle.forEach(bundle => {
          if (!contracts[bundle.contract]) {
            contracts[bundle.contract] = []
          }

          if (
            this.availabilityGrouped(workTimeCollection.employee.id) ===
            undefined
          ) {
            contracts[bundle.contract].push({
              employee: workTimeCollection.employee,
              bundle,
            })
            return
          }

          contracts[bundle.contract].unshift({
            employee: workTimeCollection.employee,
            bundle,
          })
        })
      })

      return contracts
    },

    availabilityGrouped() {
      const employeeAvailability = {}

      this.availability.forEach(x => {
        if (employeeAvailability[x.employee] === undefined) {
          employeeAvailability[x.employee] = 1
          return
        }

        employeeAvailability[x.employee] += 1
      })

      return id => employeeAvailability[id]
    },
  },

  methods: {
    getWorkTime(...args) {
      return getWorkTime.call(this, ...args)
    },

    showClass(...args) {
      return showClass.call(this, ...args)
    },

    clicked(value) {
      this.$emit('change-active-element', value)
      this.$emit('action-click-start', {
        type: StaffSchedulingType.Employee,
        value,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.active-employee {
  color: red;
}
.employee-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.muted {
  color: #b9b9c3;
}

.contract-scroll {
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  max-height: calc(21px * 4);
}
</style>
