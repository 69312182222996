<template>
  <div class="wrapper" :class="{ open: visible }">
    <div
      class="header"
      :class="{ collapsed: !visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <span class="lead collapse-title">{{ title }}</span>
      </slot>
    </div>

    <b-collapse :id="collapseItemID" v-model="visible" role="tabpanel">
      <slot />
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
    }
  },

  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },

  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 0.5rem;
  position: relative;
  &.open {
    padding-bottom: 0.5rem;
  }
}
.header {
  position: relative;
  padding-bottom: 0.5rem;
  span {
    font-size: 13px;
  }
}
</style>
