<template>
  <div v-if="activeLayer">
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      variant="outline-secondary"
      class="mr-2"
      @click="$emit('worksheet-service-provider')"
    >
      {{
        $t('StaffScheduling.WorksheetServiceProvider.WorksheetServiceProvider')
      }}
    </b-button>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      variant="outline-secondary"
      class="mr-2"
      @click="$emit('export')"
    >
      {{ $t('StaffScheduling.Export') }}
    </b-button>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="$emit('transmit')"
    >
      {{ $t('StaffScheduling.Transmit') }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },

  props: {
    activeLayer: {
      type: String,
      default: () => null,
    },
  },
}
</script>
