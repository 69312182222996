<template>
  <div>
    <div
      v-for="(serviceProvider, serviceProviderIndex) in activeAndSelected"
      :key="serviceProvider.id"
      :draggable="isAdmin"
      class="smaller-font"
      @dragstart="onDragStart(serviceProvider)"
      @dragend="onDargEnd"
      @contextmenu.prevent="openContext($event, serviceProvider)"
    >
      <span>{{ serviceProvider.name }}</span>
      <div
        v-if="serviceProviderIndex + 1 !== activeAndSelected.length"
        class="hr"
      />
    </div>
    <div v-if="activeAndSelected.length === 0" class="smaller-font text-center">
      {{ $t('StaffScheduling.NoServiceProviders') }}
    </div>
    <vue-context ref="menu">
      <li>
        <b-link class="d-flex align-items-center" @click="fillRest()">
          <feather-icon icon="FilterIcon" size="16" />
          <span v-if="context" class="ml-75">
            {{ $t('StaffScheduling.FillRest', { subject: context.name }) }}
          </span>
        </b-link>
      </li>
      <li>
        <b-link class="d-flex align-items-center" @click="getLink(context.id)">
          <feather-icon icon="LinkIcon" size="16" />
          <span v-if="context" class="ml-75">
            {{
              $t('StaffScheduling.GetServiceProviderLink', {
                calendarWeek,
              })
            }}
          </span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import VueContext from 'vue-context'
import { BLink } from 'bootstrap-vue'
import moment from 'moment'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { StaffSchedulingType } from '../../enums'

export default {
  components: {
    VueContext,
    BLink,
  },

  props: {
    date: {
      type: Number,
      required: true,
    },
    serviceProviders: {
      type: Array,
      required: true,
    },
    activeLayer: {
      type: String,
      default: () => null,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    context: null,
  }),

  computed: {
    activeAndSelected() {
      return this.serviceProviders
        .filter(
          x =>
            x.active &&
            x.staffSchedulingOver &&
            x.hourlyWagesLayers.map(y => y.layer).includes(this.activeLayer),
        )
        .filter(x => {
          if (!this.searchTerm) return true
          return x.name.includes(this.searchTerm)
        })
    },
    calendarWeek() {
      return moment(this.date).isoWeek()
    },
  },

  methods: {
    onDragStart(value) {
      this.$emit('action-drag-start', {
        type: StaffSchedulingType.ServiceProvider,
        value,
      })
    },

    openContext(event, context) {
      this.context = context
      this.$refs.menu.open(event)
    },

    fillRest() {
      this.$emit('fill-rest', this.context.id)
    },

    async fetchLink(id) {
      try {
        const { data } = await this.$axios.post(
          `auth/service-provider-token/${id}/${this.date}`,
        )
        return data
      } catch {
        this.$alert.error()
        return null
      }
    },

    async getLink(id) {
      const link = await this.fetchLink(id)
      if (link === null) return

      try {
        await this.$copyText(link)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Global.Copied'),
            icon: 'BellIcon',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Global.CanCopy'),
            icon: 'BellIcon',
          },
        })
      }
    },

    onDargEnd() {
      this.$emit('action-drag-end')
    },
  },
}
</script>

<style lang="scss" scoped>
.smaller-font {
  font-size: 12px;
}

.hr {
  height: 1px;
  background-color: #d8d8d8;
  width: 96%;
  margin-left: 2%;
  margin-bottom: 3px;
  margin-top: 3px;
}
</style>
