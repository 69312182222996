<template>
  <div>
    <div
      v-for="serviceProvider in activeAndSelected"
      :key="serviceProvider.id"
      @click="clicked(serviceProvider)"
      @contextmenu.prevent="openContext($event, serviceProvider)"
    >
      <div
        :class="{
          'active-service-provider': serviceProvider === activeElement,
        }"
      >
        {{ serviceProvider.name }}
      </div>
    </div>
    <div v-if="activeAndSelected.length === 0">
      {{ $t('StaffScheduling.NoServiceProviders') }}
    </div>
  </div>
</template>

<script>
import { StaffSchedulingType } from '../../../../enums'

export default {
  props: {
    activeElement: {
      type: Object,
      default: () => null,
    },
    currentContext: {
      type: Object,
      default: () => null,
    },
    serviceProviders: {
      type: Array,
      required: true,
    },
    activeLayer: {
      type: String,
      default: () => null,
    },
    searchTerm: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    context: null,
  }),

  computed: {
    activeAndSelected() {
      return this.serviceProviders
        .filter(
          x =>
            x.active &&
            x.staffSchedulingOver &&
            x.hourlyWagesLayers.map(y => y.layer).includes(this.activeLayer),
        )
        .filter(x => {
          if (!this.searchTerm) return true
          return x.name.includes(this.searchTerm)
        })
    },
  },

  methods: {
    clicked(value) {
      this.$emit('change-active-element', value)
      this.$emit('action-click-start', {
        type: StaffSchedulingType.ServiceProvider,
        value,
      })
    },

    openContext(event, context) {
      this.context = context
      this.$refs.menu.open(event)
    },

    fillRest() {
      this.$emit('fill-rest', this.context.id)
    },

    onDargEnd() {
      this.$emit('action-drag-end')
    },
  },
}
</script>

<style lang="scss" scoped>
.active-service-provider {
  color: red;
}
</style>
