var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center position-relative"},[_c('vue-autosuggest',{attrs:{"value":_vm.searchTerm,"suggestions":_vm.filteredEmployees,"section-configs":_vm.sectionConfigs,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
      class: 'form-control width',
      placeholder: _vm.$t('Global.Search'),
    }},on:{"input":_vm.onInputChange,"onSelect":_vm.selectHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var suggestion = ref.suggestion;
return [(suggestion.name === _vm.SUGGESTION_CONFIG.employees)?_c('span',{staticClass:"my-suggestion-item",class:{
          muted: !suggestion.item.employee.active,
        }},[_vm._v(" "+_vm._s(suggestion.item.employee.firstname)+" "+_vm._s(suggestion.item.employee.lastname)+" ")]):_c('span',{class:{
          muted: !suggestion.item.active,
        }},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")])]}}])}),_c('span',{staticClass:"x-icon"},[(_vm.searchTerm !== '')?_c('feather-icon',{attrs:{"icon":"XIcon","size":"20"},on:{"click":function($event){return _vm.onInputChange('')}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }