<template>
  <vue-context ref="menu">
    <li
      v-if="
        context &&
        context.active.attendance !== StaffSchedulingAttendance.available
      "
    >
      <b-link
        class="d-flex align-items-center"
        @click="updateAttendance(StaffSchedulingAttendance.available)"
      >
        <feather-icon icon="UserXIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.ChangeAttendanceToAvailable') }}
        </span>
      </b-link>
    </li>
    <li
      v-if="
        context &&
        context.active.attendance !== StaffSchedulingAttendance.absent
      "
    >
      <b-link
        class="d-flex align-items-center"
        @click="updateAttendance(StaffSchedulingAttendance.absent)"
      >
        <feather-icon icon="UserCheckIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.ChangeAttendanceToAbsent') }}
        </span>
      </b-link>
    </li>
    <li>
      <b-link
        class="d-flex align-items-center"
        @click="handleAction('change-start-time')"
      >
        <feather-icon icon="ClockIcon" size="16" />
        <span class="ml-75">{{ $t('StaffScheduling.ChangeStartTime') }}</span>
      </b-link>
    </li>
    <li>
      <b-link
        class="d-flex align-items-center"
        @click="handleAction('change-end-time')"
      >
        <feather-icon icon="ClockIcon" size="16" />
        <span class="ml-75">{{ $t('StaffScheduling.ChangeEndTime') }}</span>
      </b-link>
    </li>
    <li>
      <b-link class="d-flex align-items-center" @click="updateNote">
        <feather-icon icon="MessageSquareIcon" size="16" />
        <span class="ml-75">{{ $t('StaffScheduling.ChangeNote') }}</span>
      </b-link>
    </li>
  </vue-context>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import VueContext from 'vue-context'
import StaffSchedulingAttendance from '@/views/loggedin/manage-shifts/enums'

export default {
  components: {
    VueContext,
    BLink,
  },

  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    context: null,

    StaffSchedulingAttendance,
  }),

  methods: {
    open(event, context) {
      this.context = context
      this.$refs.menu.open(event)
    },

    close() {
      this.$refs.menu.close()
    },

    updateAttendance(attendance) {
      this.$emit('update-attendance', { context: this.context, attendance })
    },

    updateNote() {
      this.$emit('update-note', this.context)
    },

    handleAction(action) {
      this.$emit(action, this.context)
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/libs/vue-context.scss';
</style>
