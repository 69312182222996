<template>
  <div class="d-flex align-items-center position-relative">
    <vue-autosuggest
      :value="searchTerm"
      :suggestions="filteredEmployees"
      :section-configs="sectionConfigs"
      :get-suggestion-value="getSuggestionValue"
      :input-props="{
        class: 'form-control width',
        placeholder: $t('Global.Search'),
      }"
      @input="onInputChange"
      @onSelect="selectHandler"
    >
      <template slot-scope="{ suggestion }">
        <span
          v-if="suggestion.name === SUGGESTION_CONFIG.employees"
          class="my-suggestion-item"
          :class="{
            muted: !suggestion.item.employee.active,
          }"
        >
          {{ suggestion.item.employee.firstname }}
          {{ suggestion.item.employee.lastname }}
        </span>
        <span
          v-else
          :class="{
            muted: !suggestion.item.active,
          }"
        >
          {{ suggestion.item.name }}
        </span>
      </template>
    </vue-autosuggest>
    <span class="x-icon">
      <feather-icon
        v-if="searchTerm !== ''"
        icon="XIcon"
        size="20"
        @click="onInputChange('')"
      />
    </span>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { StaffSchedulingType } from '../enums'

const SUGGESTION_CONFIG = {
  employees: 'employees',
  serviceProviders: 'serviceProviders',
}

const sortBoolean = (a, b) => {
  if (a.active === b.active) return 0
  return a.active ? -1 : 1
}

export default {
  components: {
    VueAutosuggest,
  },

  props: {
    employees: {
      type: Array,
      required: true,
    },
    serviceProviders: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      SUGGESTION_CONFIG,
      sectionConfigs: {
        default: {
          onSelected: null,
        },
        [SUGGESTION_CONFIG.employees]: {
          label: this.$t('StaffScheduling.Employees'),
          onSelected: this.selectHandler,
        },
        [SUGGESTION_CONFIG.serviceProviders]: {
          label: this.$t('StaffScheduling.ServiceProviders'),
          onSelected: this.selectHandler,
        },
      },

      searchTerm: '',
    }
  },

  computed: {
    filteredEmployees() {
      return [
        {
          name: SUGGESTION_CONFIG.employees,
          data: this.employees
            .filter(x =>
              `${x.employee.firstname} ${x.employee.lastname}`
                .toLowerCase()
                .includes(this.searchTerm),
            )
            .sort(sortBoolean),
        },
        {
          name: SUGGESTION_CONFIG.serviceProviders,
          data: this.serviceProviders
            .filter(x => x.name.toLowerCase().includes(this.searchTerm))
            .sort(sortBoolean),
        },
      ]
    },
  },

  methods: {
    onInputChange(searchTerm) {
      if (searchTerm === '') {
        this.$emit('search', {
          searchTerm: searchTerm.toLowerCase(),
          type: null,
        })
      }
      this.searchTerm = searchTerm.toLowerCase()
    },

    getSuggestionValue(suggestion) {
      if (suggestion.name === SUGGESTION_CONFIG.employees) {
        return `${suggestion.item.firstname} ${suggestion.item.lastname}`
      }
      return suggestion.item.name
    },

    selectHandler({ item, name }) {
      if (name === SUGGESTION_CONFIG.employees) {
        this.$emit('search', {
          searchTerm: `${item.employee.firstname} ${item.employee.lastname}`,
          type: StaffSchedulingType.Employee,
          id: item.employee.id,
        })
        this.searchTerm = `${item.employee.firstname} ${item.employee.lastname}`
        return
      }
      this.$emit('search', {
        searchTerm: item.name,
        type: StaffSchedulingType.ServiceProvider,
        id: item.id,
      })
      this.searchTerm = item.name
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.width {
  width: 300px;
}

.x-icon {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}
</style>
