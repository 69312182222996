<template>
  <b-popover
    v-if="context"
    ref="popover"
    :target="context.target"
    :show.sync="popoverShow"
    :container="context.target"
    triggers="click"
    placement="auto"
    @shown="onShown"
  >
    <template v-slot:title>
      <div class="d-flex justify-content-between align-items-center">
        <span>{{ serviceProvider }}</span>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="close"
          variant="transparent"
          aria-label="Close"
          @click="handleClose"
        >
          <span class="d-inline-block text-white" aria-hidden="true">
            &times;
          </span>
        </b-button>
      </div>
    </template>

    <div>
      <b-form-input ref="name" v-model="name" size="sm" class="mb-1" />

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-secondary"
        class="mr-1"
        @click="handleClose"
      >
        {{ $t('Global.Retreat') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-primary"
        @click="handleSubmit"
      >
        {{ $t('Global.Ok') }}
      </b-button>
    </div>
  </b-popover>
</template>

<script>
import { BPopover, BButton, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BPopover,
    BButton,
    BFormInput,
  },

  directives: {
    Ripple,
  },

  props: {
    serviceProviders: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    popoverShow: false,
    context: null,
    name: null,
  }),

  computed: {
    serviceProvider() {
      const id = this.context.active.serviceProvider
      const serviceProvider = this.serviceProviders.find(x => x.id === id)
      return serviceProvider.name
    },
  },

  methods: {
    handleClose() {
      this.popoverShow = false
    },

    handleSubmit() {
      this.$emit('change-name', {
        context: this.context.context,
        value: this.name,
        entry: this.context.entry,
      })
      this.handleClose()
    },

    show(context) {
      this.context = context
      this.name = context.active.serviceProviderName
      this.popoverShow = true
    },

    onShown() {
      this.focusRef(this.$refs.name)
    },

    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus()
        })
      })
    },
  },
}
</script>
