var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((Object.keys(_vm.categorizeEmployeesByContract)),function(contract){return _c('collapse-item',{key:contract,attrs:{"is-visible":true,"title":_vm.$t(("Management.Employees.Contract.Types." + contract))}},[_c('div',{staticClass:"employee-grid"},[_c('h6',{staticClass:"small-heading"},[_vm._v(_vm._s(_vm.$t('StaffScheduling.Name')))]),_c('h6',{staticClass:"text-right small-heading"},[_vm._v(" "+_vm._s(_vm.$t('StaffScheduling.Shifts'))+" ")])]),_c('div',{staticClass:"contract-scroll"},_vm._l((_vm.categorizeEmployeesByContract[contract]),function(workTimeCollection,workTimeCollectionIndex){
var _obj;
return _c('div',{key:workTimeCollection.key,class:{
          muted:
            _vm.availabilityGrouped(workTimeCollection.employee.id) === undefined,
        }},[_c('div',{staticClass:"d-flex align-items-center justify-content-between gap-10"},[_c('div',{staticClass:"position-relative"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"HeartIcon","size":"16","badge":_vm.getDesiredCount(workTimeCollection.employee.id) || '0',"badge-classes":"badge-primary badge-small"}})],1),_c('div',{staticClass:"employee-grid flex-1"},[_c('div',{staticClass:"smaller-font",attrs:{"draggable":_vm.isAdmin},on:{"dragstart":function($event){return _vm.onDragStart(workTimeCollection.employee)},"dragend":_vm.onDargEnd}},[_vm._v(" "+_vm._s(workTimeCollection.employee.firstname)+" "+_vm._s(workTimeCollection.employee.lastname)+" ")]),_c('div',{staticClass:"text-center smaller-font d-flex align-items-center justify-content-end",class:( _obj = {}, _obj[_vm.showClass(workTimeCollection.bundle, contract)] = true, _obj )},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.html",value:(
                  _vm.getToolTip(workTimeCollection.bundle)
                ),expression:"\n                  getToolTip(workTimeCollection.bundle)\n                ",modifiers:{"hover":true,"top":true,"html":true}}]},[_vm._v(" "+_vm._s(_vm.getWorkTime(workTimeCollection.bundle))+" / "+_vm._s(_vm.ContactLayersToWork[contract].value)+" ")])])])]),_c('div',{staticClass:"hr-wrapper"},[(
              workTimeCollectionIndex + 1 !==
              _vm.categorizeEmployeesByContract[contract].length
            )?_c('div',{staticClass:"hr"}):_vm._e()])])}),0)])}),(_vm.employeesForLayer.length === 0)?_c('div',{staticClass:"smaller-font text-center mr-1"},[_vm._v(" "+_vm._s(_vm.$t('StaffScheduling.NoEmployees'))+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }