<template>
  <div v-if="config">
    <Calendar v-if="activeLayerPopulated" :date="date" :calendar="calendar">
      <template #frontRow="{ rowIndex }">
        <div v-if="config.layers[rowIndex]">
          {{ config.layers[rowIndex].name }}
        </div>
        <div v-if="config.layers[rowIndex]">
          {{ config.layers[rowIndex].startTime }}
          -
          {{ config.layers[rowIndex].endTime }}
        </div>
      </template>
      <template v-slot="{ context }">
        <div
          v-if="
            context.indexConfig.groupIndex !== null &&
            config.groups[context.indexConfig.groupIndex]
          "
          class="smaller-font"
        >
          <div
            v-b-tooltip.hover.top="
              config.groups[context.indexConfig.groupIndex].name
            "
            class="status-bar"
            :style="{
              backgroundColor:
                config.groups[context.indexConfig.groupIndex].color,
            }"
          >
            <Orders
              :config="config"
              :orders-of-line="context.orders"
              :context="context"
            />
          </div>
          <div class="entries">
            <div v-for="entry in context.value" :key="entry.insertedIndex">
              <div
                v-if="!isPast(entry.date)"
                class="d-flex transition-highlight"
                :class="{
                  highlight: entry.id === highlight,
                  [getAttendance(context, entry)]: true,
                  ['is-sick']: entry.isSick,
                }"
                @contextmenu="
                  e =>
                    openContextMenu(e, {
                      context,
                      entry,
                    })
                "
              >
                <div class="icon-wrapper">
                  <Status :status="displayStatus(context, entry)" />
                  <Note
                    :active="findActiveValues(context, entry)"
                    :is-admin="isAdmin"
                    :context="context"
                    :entry="entry"
                    @change-note="changeNote"
                  />
                </div>
                <span class="user-select-none count">
                  {{ entry.insertedIndex + 1 }}.
                </span>
                <span v-if="dragContext === null" class="drag-zone-empty">
                  <SearchHighlight
                    :tooltip="getEmployeeTooltip(context, entry)"
                    :content="displayContent(context, entry)"
                    :search-term="searchTerm"
                    :small="displayServiceProvider(context, entry)"
                  />
                </span>
                <span
                  v-else-if="checkBlocked(context, entry, dragContext)"
                  class="drag-zone blocked"
                >
                  <SearchHighlight
                    :tooltip="getEmployeeTooltip(context, entry)"
                    :content="displayContent(context, entry)"
                    :search-term="searchTerm"
                    :small="displayServiceProvider(context, entry)"
                  />
                </span>
                <span
                  v-else-if="checkIsAbsent(context, entry, dragContext)"
                  class="drag-zone blocked-absent"
                >
                  <SearchHighlight
                    :tooltip="getEmployeeTooltip(context, entry)"
                    :content="displayContent(context, entry)"
                    :search-term="searchTerm"
                    :small="displayServiceProvider(context, entry)"
                  />
                </span>
                <span
                  v-else
                  :class="`drag-zone ${getAvailabilityCssClass(context)}`"
                  @drop="() => drop(context, entry)"
                  @dragover="allowDrop"
                >
                  <SearchHighlight
                    :tooltip="getEmployeeTooltip(context, entry)"
                    :content="displayContent(context, entry)"
                    :search-term="searchTerm"
                    :small="displayServiceProvider(context, entry)"
                  />
                </span>
              </div>
              <div
                v-else
                class="d-flex transition-highlight"
                :class="{
                  highlight: entry.id === highlight,
                  [getAttendance(context, entry)]: true,
                  ['is-sick']: entry.isSick,
                }"
                @contextmenu="openPastContextMenu($event, context, entry)"
              >
                <div class="icon-wrapper">
                  <Status :status="displayStatus(context, entry)" />
                  <Note
                    :active="findActiveValues(context, entry)"
                    :context="context"
                    :entry="entry"
                    :is-admin="isAdmin"
                    @change-note="changeNote"
                  />
                </div>
                <span class="user-select-none count">
                  {{ entry.insertedIndex + 1 }}.
                </span>
                <span class="drag-zone-empty">
                  <SearchHighlight
                    :tooltip="getEmployeeTooltip(context, entry)"
                    :content="displayContent(context, entry)"
                    :search-term="searchTerm"
                    :small="displayServiceProvider(context, entry)"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else />
      </template>
    </Calendar>
    <div v-else class="text-center mt-1">
      {{ $t('StaffScheduling.NoLayerSelected') }}
    </div>
    <ContextMenu
      ref="context-menu"
      :is-admin="isAdmin"
      :config="config"
      @remove="remove"
      @change-name="showServiceProviderName"
      @update-attendance="updateAttendance"
      @update-note="openNoteModal"
      @handle-status="handleStatus"
      @change-end-time="changeEndTime"
      @change-start-time="changeStartTime"
    />
    <PastContextMenu
      ref="past-context-menu"
      :is-admin="isAdmin"
      @update-attendance="updateAttendance"
      @update-note="openNoteModal"
      @change-end-time="changeEndTime"
      @change-start-time="changeStartTime"
    />
    <UpdateNoteModal
      ref="update-note-modal"
      :config="config"
      :is-admin="isAdmin"
      @change-note="changeNote"
    />
    <ServiceProviderName
      v-if="isAdmin"
      ref="service-provider-name"
      :service-providers="serviceProviders"
      @change-name="changeServiceProviderName"
    />
    <ChangeTimeModal
      ref="change-time-modal"
      :config="config"
      @change-time="changeTime"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { getUserData } from '@/auth/utils'
import StaffSchedulingAttendance from '@/views/loggedin/manage-shifts/enums'
import Calendar from './calendar.vue'
import Status from './status.vue'
import Note from './note.vue'
import ServiceProviderName from './service-provider-name.vue'
import ContextMenu from './contextMenu.vue'
import PastContextMenu from './past-context-menu.vue'
import UpdateNoteModal from './update-note-modal.vue'
import Orders from './orders.vue'

import {
  handleDrop,
  checkAvailability,
  displayContent,
  display,
  findIndexInCreatePool,
  adjustmentWorkTime,
  dropCheck,
  checkRestTime,
  checkRestTimeSync,
  displayStatus,
  remove,
  insertContent,
  handleStatus,
  updateAttendance,
  findActiveValues,
  fillRest,
  changeNote,
  changeServiceProviderName,
  changeTime,
} from '../../utility/handleDragDrop'
import { checkIsAbsent } from '../../utility/absent'

import SearchHighlight from '../search-highlight.vue'
import {
  StaffSchedulingStatus,
  StaffSchedulingType,
  AvailabilityStatus,
} from '../../enums'
import ChangeTimeModal from './change-time-modal.vue'

export default {
  components: {
    Calendar,
    Status,
    SearchHighlight,
    ContextMenu,
    ServiceProviderName,
    Note,
    Orders,
    PastContextMenu,
    UpdateNoteModal,
    ChangeTimeModal,
  },

  props: {
    date: {
      type: Number,
      required: true,
    },
    calendar: {
      type: Array,
      required: true,
    },
    availability: {
      type: Array,
      required: true,
    },
    serviceProviders: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    activeLayerPopulated: {
      type: Object,
      default: () => null,
    },
    calendarCache: {
      type: Object,
      required: true,
    },
    dragContext: {
      type: Object,
      default: () => null,
    },
    highlight: {
      type: String,
      default: () => null,
    },
    createPool: {
      type: Array,
      required: true,
    },
    updatePool: {
      type: Array,
      required: true,
    },
    deletePool: {
      type: Array,
      required: true,
    },
    workTimeAdjustments: {
      type: Object,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    absences: {
      type: Array,
      required: true,
    },
  },

  computed: {
    config() {
      if (this.activeLayerPopulated === null) return null
      return this.activeLayerPopulated.configuration
    },
    isPast() {
      const today = moment().subtract(1, 'day')
      return date => today.isAfter(moment(date, 'YYYY-MM-DD'))
    },
    poolObserver() {
      return `${this.createPool.length}-${this.updatePool.length}-${this.deletePool.length}`
    },
  },

  watch: {
    poolObserver() {
      this.$emit('pool-update')
    },
  },

  methods: {
    showServiceProviderName(context) {
      this.$refs['service-provider-name'].show(context)
    },

    changeStartTime(context) {
      this.$refs['change-time-modal'].show('startTime', context)
    },

    changeEndTime(context) {
      this.$refs['change-time-modal'].show('endTime', context)
    },

    displayServiceProvider(context, entry) {
      const active = this.findActiveValues(context, entry)
      if (
        active.type !== StaffSchedulingType.ServiceProvider ||
        !active.serviceProviderName
      ) {
        return null
      }

      return this.serviceProviders.find(x => x.id === active.serviceProvider)
        .name
    },

    hasUserAccessToShift(context) {
      if (this.isAdmin) return true

      const group = this.config.groups[context.indexConfig.groupIndex]

      const user = getUserData()

      if (group.shiftSupervisor && group.shiftSupervisor.id === user.id) {
        return true
      }

      if (group.deputy && group.deputy.id === user.id) {
        return true
      }

      return false
    },

    openContextMenu(event, { context, entry }) {
      this.$refs['past-context-menu'].close()

      if (this.hasUserAccessToShift(context) === false) return

      if (!this.isAdmin && !this.config.canManagePastAttendance) return

      const active = this.findActiveValues(context, entry)
      if (active.type === null) return

      event.preventDefault()
      this.$refs['context-menu'].open(event, {
        context,
        entry,
        active,
        target: event.target,
      })
    },

    openPastContextMenu(event, context, entry) {
      this.$refs['context-menu'].close()

      if (this.hasUserAccessToShift(context) === false) return

      if (!this.isAdmin && !this.config.canManagePastAttendance) return

      const active = this.findActiveValues(context, entry)
      if (active.type === null) return

      event.preventDefault()
      this.$refs['past-context-menu'].open(event, {
        context,
        entry,
        active,
      })
    },

    openNoteModal(context) {
      this.$refs['update-note-modal'].show(context)
    },

    checkBlocked(context, entry, dragContext) {
      const restTime = this.checkRestTimeSync(context, entry, dragContext)

      const status = this.displayStatus(context, entry)

      const statusBlock =
        status === StaffSchedulingStatus.transmitted ||
        status === StaffSchedulingStatus.canceled ||
        status === StaffSchedulingStatus.rejected ||
        status === StaffSchedulingStatus.approved

      return restTime || statusBlock
    },

    getAttendance(context, entry) {
      const active = this.findActiveValues(context, entry)
      return active.attendance
    },

    getAvailabilityCssClass(context) {
      const availability = this.checkAvailability(context)

      switch (availability) {
        case AvailabilityStatus.available:
          return 'drag-available'

        case AvailabilityStatus.unavailable:
          return 'drag-not-available'

        case AvailabilityStatus.prioritized:
          return 'drag-prioritized'

        default:
          return ''
      }
    },

    allowDrop(event) {
      event.preventDefault()
    },

    getEmployeeTooltip(_, entry) {
      if (entry.isSick === true) {
        if (entry.attendance === StaffSchedulingAttendance.absent) {
          return this.$t('StaffScheduling.AbsentSick')
        }

        if (entry.attendance === StaffSchedulingAttendance.available) {
          return this.$t('StaffScheduling.AvailableSick')
        }

        return this.$t('StaffScheduling.Sick')
      }

      if (entry.attendance === StaffSchedulingAttendance.absent) {
        return this.$t('StaffScheduling.Absent')
      }

      if (entry.attendance === StaffSchedulingAttendance.available) {
        return this.$t('StaffScheduling.Available')
      }

      return undefined
    },

    displayStatus(...args) {
      return displayStatus.call(this, ...args)
    },

    findActiveValues(...args) {
      return findActiveValues.call(this, ...args)
    },

    insertContent(...args) {
      return insertContent.call(this, ...args)
    },

    handleStatus(...args) {
      return handleStatus.call(this, ...args)
    },

    updateAttendance(...args) {
      return updateAttendance.call(this, ...args)
    },

    remove(...args) {
      return remove.call(this, ...args)
    },

    checkAvailability(...args) {
      return checkAvailability.call(this, ...args)
    },

    displayContent(...args) {
      return displayContent.call(this, ...args)
    },

    changeServiceProviderName(...args) {
      return changeServiceProviderName.call(this, ...args)
    },

    async drop(...args) {
      return handleDrop.call(this, ...args)
    },

    async checkRestTime(...args) {
      return checkRestTime.call(this, ...args)
    },

    checkRestTimeSync(...args) {
      return checkRestTimeSync.call(this, ...args)
    },

    fillRest(...args) {
      return fillRest.call(this, ...args)
    },

    display(...args) {
      return display.call(this, ...args)
    },

    changeNote(...args) {
      return changeNote.call(this, ...args)
    },

    changeTime(...args) {
      return changeTime.call(this, ...args)
    },

    findIndexInCreatePool(...args) {
      return findIndexInCreatePool.call(this, ...args)
    },

    adjustmentWorkTime(...args) {
      return adjustmentWorkTime.call(this, ...args)
    },

    checkIsAbsent(...args) {
      return checkIsAbsent.call(this, ...args)
    },

    async dropCheck(...args) {
      return dropCheck.call(this, ...args)
    },
  },
}
</script>

<style lang="scss" scoped>
.status-bar {
  width: 100%;
  height: 20px;
  position: relative;
  background-color: var(--bg-color);
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 20px;
    background-color: var(--bg-color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
  }
}

.transition-highlight {
  position: relative;

  &::after {
    transition: 500ms ease-in-out all;
    content: '';
    display: block;
    width: 0%;
    position: absolute;
    height: 2px;
    background-color: var(--danger);
    bottom: 0;
  }
}

.highlight {
  &::after {
    width: 100%;
  }
}

.entries {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  gap: 0.5rem;
}

.count {
  min-width: 1rem;
  align-self: center;
}

.drag-zone {
  flex: 1;
  transition: all 500ms ease-in-out;
  border: 2px #ebe9f1;
  padding-left: 4px;
  border-radius: 1rem;
}

.drag-zone-empty {
  flex: 1;
  border: 2px transparent solid;
  padding-left: 4px;
  border-radius: 1rem;
  transition: all 500ms ease-in-out;
}

.blocked {
  border-style: solid;
  border-color: var(--danger);
}

.blocked-absent {
  border-style: solid;
  border-color: var(--danger);
}

.drag-not-available {
  border-style: dotted;
}
.drag-available {
  border-style: solid;
}

.drag-prioritized {
  border-style: solid;
  border-color: #bbe292;
}

.smaller-font {
  font-size: 12px;
}

.available span:last-child {
  color: var(--success);
}

.absent span:last-child {
  text-decoration: line-through var(--danger) 1px;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.is-sick span:last-child {
  text-decoration: underline dashed var(--danger) 1px;
  text-underline-offset: 4px;
}
</style>
