<template>
  <div class="menu">
    <div v-if="show.showName">
      <div
        class="d-flex align-items-center"
        @click="handleAction('change-name')"
      >
        <feather-icon icon="ChevronsRightIcon" size="16" />
        <span class="ml-75">{{ $t('StaffScheduling.ChangeName') }}</span>
      </div>
    </div>
    <div v-if="show.showTransmit">
      <div
        class="d-flex align-items-center"
        @click="handleStatus('transmitted')"
      >
        <feather-icon icon="ChevronsRightIcon" size="16" />
        <span class="ml-75">{{
          $t('StaffScheduling.StatusAction.transmitted')
        }}</span>
      </div>
    </div>
    <div v-if="show.showApprove">
      <div class="d-flex align-items-center" @click="handleStatus('approved')">
        <feather-icon icon="UserCheckIcon" size="16" />
        <span class="ml-75">{{
          $t('StaffScheduling.StatusAction.approved')
        }}</span>
      </div>
    </div>
    <div v-if="show.showCancel">
      <div class="d-flex align-items-center" @click="handleStatus('canceled')">
        <feather-icon icon="XIcon" size="16" />
        <span class="ml-75">{{
          $t('StaffScheduling.StatusAction.canceled')
        }}</span>
      </div>
    </div>
    <div v-if="show.showReject">
      <div class="d-flex align-items-center" @click="handleStatus('rejected')">
        <feather-icon icon="UserXIcon" size="16" />
        <span class="ml-75">{{
          $t('StaffScheduling.StatusAction.rejected')
        }}</span>
      </div>
    </div>
    <div v-if="show.showRemove">
      <div class="d-flex align-items-center" @click="handleAction('remove')">
        <feather-icon icon="Trash2Icon" size="16" />
        <span class="ml-75">{{
          $t('StaffScheduling.StatusAction.deleted')
        }}</span>
      </div>
    </div>
    <div v-if="show.showCancellationNoted">
      <div
        class="d-flex align-items-center"
        @click="handleStatus('cancellationNoted')"
      >
        <feather-icon icon="SlashIcon" size="16" />
        <span class="ml-75">{{
          $t('StaffScheduling.StatusAction.cancellationNoted')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { StaffSchedulingStatus, StaffSchedulingType } from '../../../enums'

export default {
  props: {
    elem: {
      type: Object,
      default: () => null,
    },
    context: {
      type: Object,
      default: () => null,
    },
    active: {
      type: Object,
      default: () => null,
    },
    status: {
      type: String,
      default: () => null,
    },
  },

  computed: {
    contextMenu() {
      return {
        context: this.context,
        entry: this.elem,
        active: this.active,
      }
    },

    show() {
      return {
        showName:
          this.active.type === StaffSchedulingType.ServiceProvider &&
          (this.status === StaffSchedulingStatus.open ||
            this.status === StaffSchedulingStatus.transmitted),
        showTransmit: this.status === StaffSchedulingStatus.open,
        showApprove: this.status === StaffSchedulingStatus.transmitted,
        showReject: this.status === StaffSchedulingStatus.transmitted,
        showCancel: this.status === StaffSchedulingStatus.approved,
        showRemove:
          this.status === StaffSchedulingStatus.open ||
          this.status === StaffSchedulingStatus.rejected ||
          this.status === StaffSchedulingStatus.cancellationNoted,
        showCancellationNoted: this.status === StaffSchedulingStatus.canceled,
      }
    },
  },

  methods: {
    handleAction(action) {
      this.$emit(action, this.contextMenu)
    },

    handleStatus(status) {
      this.$emit('handle-status', { context: this.contextMenu, status })
    },
  },
}
</script>

<style lang="scss">
.menu {
  width: 100%;
  height: 100%;
}
</style>
