import Contracts, {
  ContactLayersToWork,
} from '@/views/loggedin/management/employees/utility/contracts'

export function getWorkTime(bundle) {
  return bundle.workTime + (this.workTimeAdjustments[bundle.key] ?? 0)
}

export function showClass(bundle, contract) {
  const workTime = this.getWorkTime(bundle)

  switch (contract) {
    case Contracts.workingStudents:
    case Contracts.miniJob:
    case Contracts.shortTermEmployment:
      return workTime > ContactLayersToWork[contract].value
        ? 'text-danger'
        : null
    case Contracts.slidingZoneTariff:
      return workTime >= ContactLayersToWork[contract].value
        ? 'text-success'
        : null
    default:
      return null
  }
}
