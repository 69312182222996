<template>
  <div
    :class="
      isMobileView
        ? 'header'
        : 'p-1 d-flex align-items-center justify-content-between header-wrapper'
    "
  >
    <div
      class="d-flex"
      :class="
        isMobileView
          ? 'header-controls justify-content-center'
          : 'align-items-center header-controls'
      "
    >
      <div class="d-flex align-items-center mr-2">
        <feather-icon
          icon="ChevronLeftIcon"
          class="hover-grey"
          size="24"
          @click="previousWeek"
        />
        <feather-icon
          icon="ChevronRightIcon"
          class="hover-grey"
          size="24"
          @click="nextWeek"
        />
      </div>
      <div>
        <div v-if="isMobileView">
          <h6 class="week-span">{{ weekStart }} - {{ weekEnd }}</h6>
        </div>
        <div v-else>
          <h5 class="week-span">{{ weekStart }} - {{ weekEnd }}</h5>
        </div>
        <div class="text-center">
          {{ $t('Dates.CalendarWeek.Short') }}{{ calendarWeek }}
        </div>
      </div>
      <div class="ml-2">
        <b-form-datepicker
          :value="new Date(date)"
          v-bind="$t('Bootstrap.DatePicker')"
          button-only
          right
          :start-weekday="1"
          locale="de-De"
          button-variant="outline-secondary"
          @context="onDatepicker"
        />
      </div>
    </div>
    <div v-if="!isMobileView" class="d-flex algin-items-center">
      <Search
        :employees="employees"
        :service-providers="serviceProviders"
        @search="changeSearchTerm($event)"
      />
      <div v-if="isAdmin" class="notification" @click="$emit('open-stack')">
        <feather-icon
          v-if="stack.length !== 0"
          :badge="stack.length"
          badge-classes="bg-primary"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { BFormDatepicker } from 'bootstrap-vue'
import Search from './search.vue'

export default {
  components: {
    Search,
    BFormDatepicker,
  },

  props: {
    employees: {
      type: Array,
      default: () => null,
    },
    serviceProviders: {
      type: Array,
      default: () => null,
    },
    date: {
      type: Number,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    activeLayerPopulated: {
      type: Object,
      default: () => null,
    },
    stack: {
      type: Array,
      default: () => null,
    },
    isAdmin: {
      type: Boolean,
      default: () => null,
    },
    isMobileView: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    calendarWeek() {
      return moment(this.date).week()
    },
    weekStart() {
      return moment(this.date).startOf('week').format('DD.MM.YYYY')
    },
    weekEnd() {
      return moment(this.date).endOf('week').format('DD.MM.YYYY')
    },
  },

  methods: {
    previousWeek() {
      this.$emit('change-date', moment(this.date).subtract(1, 'week').valueOf())
    },
    nextWeek() {
      this.$emit('change-date', moment(this.date).add(1, 'week').valueOf())
    },
    changeSearchTerm(value) {
      this.$emit('change-search-term', value)
    },
    onDatepicker({ selectedDate }) {
      const timeStamp = moment(selectedDate).valueOf()
      if (timeStamp === this.date) return
      this.$emit('change-date', timeStamp)
    },
  },
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  gap: 2rem;

  .search-term-input {
    width: clamp(300px, 25vw, 500px);
  }

  .header-controls {
    min-width: 360px;
  }
}

.notification {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.header {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 90vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
