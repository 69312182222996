<template>
  <div>
    <collapse-item
      v-for="contract in Object.keys(categorizeEmployeesByContract)"
      :key="contract"
      :is-visible="true"
      :title="$t(`Management.Employees.Contract.Types.${contract}`)"
    >
      <div class="employee-grid">
        <h6 class="small-heading">{{ $t('StaffScheduling.Name') }}</h6>
        <h6 class="text-right small-heading">
          {{ $t('StaffScheduling.Shifts') }}
        </h6>
      </div>
      <div class="contract-scroll">
        <div
          v-for="(
            workTimeCollection, workTimeCollectionIndex
          ) in categorizeEmployeesByContract[contract]"
          :key="workTimeCollection.key"
          :class="{
            muted:
              availabilityGrouped(workTimeCollection.employee.id) === undefined,
          }"
        >
          <div class="d-flex align-items-center justify-content-between gap-10">
            <div class="position-relative">
              <feather-icon
                icon="HeartIcon"
                size="16"
                class="text-primary"
                :badge="getDesiredCount(workTimeCollection.employee.id) || '0'"
                badge-classes="badge-primary badge-small"
              />
            </div>
            <div class="employee-grid flex-1">
              <div
                class="smaller-font"
                :draggable="isAdmin"
                @dragstart="onDragStart(workTimeCollection.employee)"
                @dragend="onDargEnd"
              >
                {{ workTimeCollection.employee.firstname }}
                {{ workTimeCollection.employee.lastname }}
              </div>
              <div
                class="text-center smaller-font d-flex align-items-center justify-content-end"
                :class="{
                  [showClass(workTimeCollection.bundle, contract)]: true,
                }"
              >
                <span
                  v-b-tooltip.hover.top.html="
                    getToolTip(workTimeCollection.bundle)
                  "
                >
                  {{ getWorkTime(workTimeCollection.bundle) }} /
                  {{ ContactLayersToWork[contract].value }}
                </span>
              </div>
            </div>
          </div>
          <div class="hr-wrapper">
            <div
              v-if="
                workTimeCollectionIndex + 1 !==
                categorizeEmployeesByContract[contract].length
              "
              class="hr"
            />
          </div>
        </div>
      </div>
    </collapse-item>
    <div
      v-if="employeesForLayer.length === 0"
      class="smaller-font text-center mr-1"
    >
      {{ $t('StaffScheduling.NoEmployees') }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import contracts, {
  ContactLayersToWork,
} from '@/views/loggedin/management/employees/utility/contracts'

import { StaffSchedulingType } from '../../enums'
import CollapseItem from './contract-collapse.vue'

import { getWorkTime, showClass } from '../../utility/employees'

export default {
  components: {
    CollapseItem,
  },

  props: {
    employees: {
      type: Array,
      required: true,
    },
    activeLayer: {
      type: String,
      default: () => null,
    },
    workTimeAdjustments: {
      type: Object,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    availability: {
      type: Array,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    desiredCount: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    ContactLayersToWork,
  }),

  computed: {
    employeesForLayer() {
      return this.employees
        .filter(
          x =>
            this.activeLayer &&
            x.employee.active === true &&
            x.employee.layers.map(y => y.id).includes(this.activeLayer),
        )
        .filter(x => {
          if (!this.searchTerm) return true
          return `${x.employee.firstname} ${x.employee.lastname}`.includes(
            this.searchTerm,
          )
        })
    },

    categorizeEmployeesByContract() {
      const contractsDir = {}

      this.employeesForLayer.forEach(workTimeCollection => {
        workTimeCollection.workTimeBundle.forEach(bundle => {
          if (!contractsDir[bundle.contract]) {
            contractsDir[bundle.contract] = []
          }

          if (
            this.availabilityGrouped(workTimeCollection.employee.id) ===
            undefined
          ) {
            contractsDir[bundle.contract].push({
              employee: workTimeCollection.employee,
              bundle,
            })
            return
          }

          contractsDir[bundle.contract].unshift({
            employee: workTimeCollection.employee,
            bundle,
          })
        })
      })

      return contractsDir
    },

    availabilityGrouped() {
      const employeeAvailability = {}

      this.availability.forEach(x => {
        if (employeeAvailability[x.employee] === undefined) {
          employeeAvailability[x.employee] = 1
          return
        }

        employeeAvailability[x.employee] += 1
      })

      return id => employeeAvailability[id]
    },

    getDesiredCount() {
      const desiredCountDictionary = {}

      this.desiredCount.forEach(x => {
        desiredCountDictionary[x.employee] = x.amount
      })

      return employeeId => desiredCountDictionary[employeeId] ?? 0
    },
  },

  methods: {
    onDragStart(value) {
      this.$emit('action-drag-start', {
        type: StaffSchedulingType.Employee,
        value,
      })
    },

    getToolTip(bundle) {
      const date = moment(bundle.dates[0], 'YYYY-MM-DD')

      const cw = this.$t('Dates.CalendarWeek.Short')
      const year = this.$t('Dates.Units.Year')
      const month = this.$t('Dates.Units.Month')

      if (bundle.dates.length === 7) {
        switch (bundle.contract) {
          case contracts.workingStudents:
            return `${cw}${date.isoWeek()} ${date.year()}`
          case contracts.miniJob:
          case contracts.slidingZoneTariff:
            return `${month}: ${date.format('MMM')} ${date.year()}`
          case contracts.shortTermEmployment:
            return `${year}: ${date.year()}`
          default:
            return null
        }
      }

      const dates = bundle.dates
        .map(x => moment(x, 'YYYY-MM-DD').format('dd'))
        .join(', ')

      switch (bundle.contract) {
        case contracts.workingStudents:
          return `${cw}${date.isoWeek()} ${date.year()}<br>${cw}${date.isoWeek()}: ${dates}`
        case contracts.miniJob:
        case contracts.slidingZoneTariff:
          return `${month}: ${date.format(
            'MMM',
          )} ${date.year()}<br>${cw}${date.isoWeek()}: ${dates}`
        case contracts.shortTermEmployment:
          return `${year}: ${date.year()}<br>${cw}${date.isoWeek()}: ${dates}`
        default:
          return null
      }
    },

    getWorkTime(...args) {
      return getWorkTime.call(this, ...args)
    },

    showClass(...args) {
      return showClass.call(this, ...args)
    },

    onDargEnd() {
      this.$emit('action-drag-end')
    },
  },
}
</script>

<style lang="scss" scoped>
.employee-grid {
  display: grid;
  grid-template-columns: 65% 35%;
  grid-template-rows: auto;
  margin-right: 1rem;
}

.contract-scroll {
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  max-height: 160px;
}

.hr-wrapper {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  margin-top: 3px;

  .hr {
    height: 1px;
    background-color: #d8d8d8;
    width: 96%;
  }
}

.smaller-font {
  font-size: 12px;
}

.small-heading {
  font-size: 12px;
  margin-bottom: 3px;
}

.gap-10 {
  gap: 10px;
}
</style>
