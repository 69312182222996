<template>
  <b-modal
    v-if="active && entry && context && key"
    :visible.sync="showModal"
    size="sm"
    centered
    :title="
      $t(`StaffScheduling.Change${key.charAt(0).toUpperCase() + key.slice(1)}`)
    "
    :ok-title="$t('Global.Ok')"
    :cancel-title="$t('Global.Retreat')"
    :ok-disabled="!time || time.length !== 5 || time === active[key]"
    cancel-variant="outline-secondary"
    @ok="handleOk"
    @hide="close"
    @cancel="close"
  >
    <validation-observer
      ref="validation"
      tag="div"
      class="d-flex gap-4 align-items-center"
      :class="{
        'justify-content-center': active[key] !== undefined,
      }"
    >
      <div v-if="active[key]" class="space-top-label">{{ active[key] }}</div>

      <feather-icon
        v-if="active[key]"
        icon="ArrowRightIcon"
        class="space-top-label"
        size="20"
      />

      <div>
        <validation-provider
          #default="{ errors }"
          :name="
            $t(`ManageShifts.${key.charAt(0).toUpperCase() + key.slice(1)}`)
          "
          :rules="{ required: true, regex: timeRegex }"
          class="mb-1"
        >
          <label :for="key">
            {{
              $t(`ManageShifts.${key.charAt(0).toUpperCase() + key.slice(1)}`)
            }}
          </label>
          <b-input-group class="d-flex">
            <b-form-input
              :id="key"
              v-model="time"
              type="text"
              autocomplete="off"
              :state="errors.length > 0 ? false : null"
              placeholder="HH:mm"
            />
            <b-input-group-append class="group-append">
              <b-form-timepicker
                v-model="time"
                v-bind="$t('Bootstrap.TimePicker')"
                button-only
                :button-variant="
                  errors.length > 0 ? 'outline-danger' : 'outline-primary'
                "
                class="timepicker-height"
                @input="inputTime"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger d-block">{{ errors[0] }}</small>
        </validation-provider>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { timeRegex } from '@/utility/regex'
import { regex, required } from '@validations'
import {
  BFormInput,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'ChangeTimeModal',

  components: {
    BModal,
    BFormInput,
    BInputGroup,
    BFormTimepicker,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    showModal: false,
    time: '',

    key: null,
    active: null,
    entry: null,
    context: null,

    timeRegex,
    required,
    regex,
  }),

  methods: {
    inputTime() {
      this.time = this.time.substring(0, 5)
    },

    show(key, { active, entry, context }) {
      this.showModal = true

      this.active = active
      this.entry = entry
      this.context = context

      if (active[key] === undefined) {
        this.time = this.config.layers[context.indexConfig.layerIndex][key]
      } else {
        this.time = active[key]
      }

      this.key = key
    },

    async close() {
      // this is necessary to prevent deleting the active, entry, etc. before its send by the handleOk method
      await this.$refs.validation.validate()

      this.showModal = false

      this.active = null
      this.entry = null
      this.context = null
      this.key = null
    },

    async handleOk() {
      const validation = await this.$refs.validation.validate()

      if (!validation) return

      this.$emit('change-time', {
        context: {
          active: this.active,
          context: this.context,
          entry: this.entry,
        },
        time: this.time,
        key: this.key,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.group-append {
  height: 38px;
}

.text-danger {
  margin-top: 0.5rem;
}

.space-top-label {
  margin-top: 18px;
}

.gap-4 {
  gap: 1rem;
}

.timepicker-height {
  > button.btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &[disabled='disabled'] {
      background-color: #efefef !important;
      border-color: #d8d6de !important;
      color: #6e6b7b !important;
    }
  }
}
</style>
