const confirm = async (
  { $bvModal, $i18n, $createElement },
  { okText, cancelText, title, text },
) => {
  try {
    const messageVNode = $createElement('div', {
      class: ['p-1'],
      domProps: {
        innerHTML: text,
        style: 'font-size: 16px',
      },
    })

    return $bvModal.msgBoxConfirm([messageVNode], {
      title,
      size: 'sm',
      okVariant: 'primary',
      cancelVariant: 'outline-secondary',
      okTitle: okText,
      cancelTitle: cancelText || $i18n.t('Global.Retreat'),
      hideHeaderClose: false,
      centered: true,
      'body-class': 'p-2',
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export default confirm
