<template>
  <div>
    <div
      v-for="grouped in groupedLayersByDepartment"
      :key="grouped.department"
      class="mb-1"
    >
      <div class="department">{{ grouped.department }}</div>

      <div
        v-for="(layer, index) in grouped.layers"
        :key="layer.id"
        class="department-layer-wrapper"
        @click="$emit('change-active-layer', layer.id)"
      >
        <div class="d-flex align-items-center department-layer-container">
          <span
            class="cursor-pointer transition-color smaller-font"
            :class="{
              'active-layer': activeLayer === layer.id,
            }"
          >
            {{ layer.area }}
          </span>
          <span
            v-if="searchResults[layer.id]"
            class="layer-badge"
            :class="{
              'active-layer-badge': activeLayer === layer.id,
            }"
          >
            <span>{{ searchResults[layer.id] }}</span>
          </span>
        </div>

        <div v-if="index !== grouped.layers.length - 1" class="hr" />
      </div>
    </div>

    <div v-if="layers.length === 0">
      {{ $t('StaffScheduling.NoLayer') }}
    </div>
  </div>
</template>

<script>
const noDepartmentAssignedKey = 'no-department-assigned'

export default {
  props: {
    activeLayer: {
      type: String,
      default: () => null,
    },
    layers: {
      type: Array,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    searchResults: {
      type: Object,
      required: true,
    },
  },

  computed: {
    groupedLayersByDepartment() {
      const map = this.getGroupedLayersByDepartmentMap()

      const groupedLayers = []

      Array.from(map.keys()).forEach(department => {
        const layers = map.get(department)

        groupedLayers.push({
          key: department,
          department:
            department === noDepartmentAssignedKey
              ? this.$t('StaffScheduling.NoDepartmentAssigned')
              : department,
          layers,
        })
      })

      groupedLayers.sort((a, b) => {
        if (a.key === noDepartmentAssignedKey) {
          return 1
        }

        if (b.key === noDepartmentAssignedKey) {
          return -1
        }

        return a.department.localeCompare(b.department)
      })

      return groupedLayers
    },
  },

  methods: {
    getGroupedLayersByDepartmentMap() {
      return this.layers.reduce((acc, layer) => {
        const key = layer.department || noDepartmentAssignedKey

        if (!acc.has(key)) {
          acc.set(key, [])
        }

        acc.get(key).push(layer)
        return acc
      }, new Map())
    },
  },
}
</script>

<style lang="scss" scoped>
.active-layer {
  color: var(--primary);
  font-weight: 600;
}

.smaller-font {
  font-size: 12px;
}

.department-layer-wrapper {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.department-layer-container {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.department {
  font-weight: 600;
  margin-bottom: 0rem;
  font-size: 1rem;
}

.layer-badge {
  margin-left: 0.25rem;
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: block;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: var(--white);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
  }
}

.active-layer-badge {
  background-color: var(--primary);
}

.hr {
  width: 100%;
  height: 1px;
  background-color: #6e6b7b;
  opacity: 0.1;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
}
</style>
