<template>
  <div class="popup">
    <div class="popup-inner">
      <div class="displayData">
        <div class="searchDiv">
          <div class="search">
            <div>
              <b-form-input
                :value="searchTerm"
                :placeholder="$t('Global.Search')"
                type="search"
                class="search-term-input"
                @input="changeSearchTerm"
              />
            </div>
          </div>
        </div>
        <div class="contentDiv">
          <div class="popUp-container">
            <div class="popUp-content">
              <collapse>
                <collapse-item
                  :title="$t('StaffScheduling.ServiceProviders')"
                  :is-visible="false"
                >
                  <ServiceProviders
                    :search-term="searchTerm"
                    :service-providers="serviceProviders"
                    :active-layer="activeLayer"
                    :active-element="activeElement"
                    @action-click-start="actionClickStart"
                    @change-active-element="changeActiveElement"
                    @fill-rest="$emit('fill-rest', $event)"
                  />
                </collapse-item>
                <collapse-item
                  :title="$t('StaffScheduling.Employees')"
                  :is-visible="false"
                >
                  <Employees
                    :search-term="searchTerm"
                    :work-time-adjustments="workTimeAdjustments"
                    :employees="employees"
                    :active-layer="activeLayer"
                    :availability="availability"
                    :active-element="activeElement"
                    @change-active-element="changeActiveElement"
                    @action-click-start="actionClickStart"
                  />
                </collapse-item>
              </collapse>
            </div>
            <div class="side-bottom" />
          </div>
        </div>
        <div class="footerDiv">
          <div class="footer">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              type="cancel"
              class="mr-4"
              @click="$emit('close-popup')"
            >
              {{ $t('Global.Retreat') }}
            </b-button>
            <b-button
              v-if="$can($acl.action.Update, $acl.subjects.Employees)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="!canConfirmChange"
              @click="$emit('confirm-changes')"
            >
              {{ $t('Global.Add') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import Collapse from '@core/components/app-collapse/AppCollapse.vue'
import CollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import Employees from './employees.vue'
import ServiceProviders from './service-providers.vue'

export default {
  components: {
    BFormInput,
    Collapse,
    CollapseItem,
    BButton,
    Employees,
    ServiceProviders,
  },
  props: {
    activeLayer: {
      type: String,
      default: () => null,
    },
    employees: {
      type: Array,
      required: true,
    },
    serviceProviders: {
      type: Array,
      required: true,
    },
    availability: {
      type: Array,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      required: true,
    },
    workTimeAdjustments: {
      type: Object,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    activeElement: null,
    canConfirmChange: false,
  }),

  methods: {
    changeActiveElement(value) {
      this.activeElement = value
    },
    changeSearchTerm(value) {
      this.$emit('change-search-term', value)
    },
    actionClickStart(value) {
      this.canConfirmChange = true
      this.$emit('action-click-start', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  .popup-inner {
    border-radius: 13px;
    margin-top: 2vh;
    width: 85vw;
    height: 50vh;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .displayData {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      .searchDiv {
        width: 100%;
        height: 15%;
        display: flex;
        align-items: center;
        border-bottom: solid 0.2px rgba(0, 0, 0, 0.2);
        justify-content: center;
        .search {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          .search-term-input {
            width: clamp(300px, 25vw, 500px);
          }
        }
      }
      .contentDiv {
        width: 100%;
        height: 72%;
        .popUp-container {
          margin-top: 0;
          display: flex;
          flex-direction: column;
          height: 100%;
          padding-top: 1rem;
          .popUp-content {
            flex: 1;
            overflow-x: auto;
            &::-webkit-scrollbar {
              width: 5px;
            }
          }
          .side-bottom {
            padding-bottom: 1rem;
            padding-top: 1rem;
          }
        }
      }
      .footerDiv {
        border-top: solid 0.2px rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 18%;
        .footer {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .button {
            margin-right: 10vw;
            background-color: lightgrey;
            height: 70%;
            width: 30%;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .button1 {
            margin-left: 10vw;
            background-color: lightgrey;
            height: 70%;
            width: 30%;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
