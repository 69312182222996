<template>
  <div
    class="d-flex align-items-center justify-content-end icon-spacer"
    :style="{
      color: contrastColor(config.groups[context.indexConfig.groupIndex].color),
    }"
  >
    <feather-icon
      v-if="show"
      v-b-tooltip.bottom="lineText"
      icon="FileTextIcon"
      size="15"
    />
  </div>
</template>

<script>
import contrastColor from '@/utility/functions/contrastColor'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: { FeatherIcon },
  props: {
    ordersOfLine: {
      type: Array,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
  },

  computed: {
    show() {
      return this.ordersOfLine.some(x => x.length !== 0)
    },

    lineText() {
      let lineText = ''

      this.ordersOfLine.forEach((orders, lineIndex) => {
        const lineName = this.config.lines[lineIndex].name

        if (orders.length === 0) return

        lineText += `${lineName}: ${orders.map(x => x.name).join(', ')}`

        if (
          lineIndex + 1 !==
          this.ordersOfLine.filter(x => x.length !== 0).length
        ) {
          lineText += ' / '
        }
      })

      return lineText
    },
  },

  methods: {
    contrastColor,
  },
}
</script>

<style lang="scss" scoped>
.icon-spacer {
  height: 100%;
  padding: 0 0.25rem 0 0.25rem;
}
</style>
