<template>
  <div class="scaffolding">
    <div class="side-area border-right">
      <slot name="side">Side Slot</slot>
    </div>
    <div class="main-area">
      <div class="border-bottom">
        <slot name="header"> Header Slot </slot>
      </div>
      <div class="content-compartment">
        <div class="content-compartment-restriction">
          <slot name="content"> Content Slot </slot>
        </div>
      </div>
      <div v-if="hasFooterSlot" class="footer-compartment border-top">
        <slot name="footer"> Footer Slot </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
}
</script>

<style lang="scss" scoped>
.scaffolding {
  display: grid;
  grid-template-columns: 250px auto;
  height: 720px;

  .side-area {
    padding: 0 0 0 1rem;
    height: 720px;
  }

  .main-area {
    display: flex;
    flex-direction: column;
    height: 720px;
    overflow-y: auto;

    .border-bottom {
      height: 74px;
      width: 100%;
    }
    .content-compartment {
      width: 100%;
      flex: 1;
      overflow-x: auto;
      .content-compartment-restriction {
        min-width: 1200px;
      }
    }
    .footer-compartment {
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
      height: 67px;
    }
  }
}
</style>
