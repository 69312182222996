<!-- eslint-disable vue/no-v-html -->
<template>
  <span v-b-tooltip.hover.top="tooltip">
    <span v-html="highlight" />
    <span v-if="small" class="text-muted">
      (<small v-html="highlightSmall" />)
    </span>
  </span>
</template>

<script>
export default {
  props: {
    searchTerm: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: () => '',
    },
    small: {
      type: String,
      default: () => null,
    },
    tooltip: {
      type: String,
      default: () => undefined,
    },
  },

  computed: {
    highlight() {
      if (!this.searchTerm || !this.content) {
        return this.content
      }
      return this.content.replace(
        new RegExp(this.searchTerm, 'gi'),
        match => `<span class="highlightText">${match}</span>`,
      )
    },
    highlightSmall() {
      if (!this.searchTerm) {
        return this.small
      }
      if (!this.small) {
        return null
      }
      return this.small.replace(
        new RegExp(this.searchTerm, 'gi'),
        match => `<span class="highlightText">${match}</span>`,
      )
    },
  },
}
</script>

<style lang="scss">
.highlightText {
  background-color: yellow;
}
</style>
