<template>
  <Mobile v-if="isMobile" />
  <Web v-else />
</template>
<script>
import Mobile from './components/mobile.vue'
import Web from './components/web.vue'

export default {
  components: {
    Mobile,
    Web,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 1000
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },
}
</script>
