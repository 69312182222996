import moment from 'moment'

export async function load(key, endpoint = key) {
  try {
    const { data } = await this.$axios.get(endpoint)
    this[key] = data
    return data
  } catch (error) {
    this.$alert.error()
    return error
  }
}

export async function loadEmployees(resetCache = true) {
  if (!resetCache) {
    this.employeesCache = {}
  }

  const date = moment(this.date).startOf('week')

  if (this.employeesCache[date.format('YYYY-MM-DD')]) {
    this.employees = this.employeesCache[date.format('YYYY-MM-DD')]
    return
  }

  const { data } = await this.$axios.get(
    `employees/with-work-time/${date.valueOf(date)}`,
  )

  this.employeesCache[date.format('YYYY-MM-DD')] = data

  this.employees = data
}

export async function initialLoading() {
  this.loading = true
  await Promise.all([
    this.loadEmployees(),
    this.load('serviceProviders', 'service-providers'),
    this.load('layers', 'layers/my-responsibilities'),
    this.isAdmin
      ? this.load('stack', 'staff-scheduling-queue/admin')
      : Promise.resolve([]),
    this.loadEmployeesDesiredCount(),
  ])

  if (this.stack.length !== 0) {
    this.openStack()
  }

  if (this.layers.length > 0) {
    const lastActiveLayerId = localStorage.getItem('last-active-layer')

    if (lastActiveLayerId) {
      const lastIndex = this.layers.findIndex(
        layer => layer.id === lastActiveLayerId,
      )

      if (lastIndex !== -1) {
        this.activeLayer = this.layers[lastIndex].id
      } else {
        this.activeLayer = this.layers[0].id
      }
    } else {
      this.activeLayer = this.layers[0].id
    }
  }

  await Promise.all([
    this.loadCalendar(),
    this.loadEmployeesAvailability(),
    this.loadAbsences(),
  ])
  this.loading = false
}

export async function loadEmployeesDesiredCount() {
  if (this.isAdmin === false) return

  const date = moment(this.date)

  const year = date.year()
  const isoWeek = date.isoWeek()

  if (this.desiredCountCache[year] && this.desiredCountCache[year][isoWeek]) {
    this.desiredCount = this.desiredCountCache[year][isoWeek]
    return
  }

  const { data } = await this.$axios.get(
    `desired-availability-count/week/${year}/${isoWeek}`,
  )

  this.desiredCount = data

  if (!this.desiredCountCache[year]) {
    this.desiredCountCache[year] = {}
  }

  this.desiredCountCache[year][isoWeek] = data
}

export async function loadAbsences() {
  if (this.activeLayer === null || this.isAdmin === false) return

  const cached = this.absencesCache[this.date]
  if (cached) {
    this.absences = cached
    return
  }

  const { data } = await this.$axios.get(`absences/week/${this.date}`)

  if (!this.absencesCache[this.date]) {
    this.absencesCache[this.date] = {}
  }

  this.absences = data
  this.absencesCache[this.date] = data
}

export async function loadEmployeesAvailability() {
  if (this.activeLayer === null || this.isAdmin === false) return

  if (!this.availabilityCache[this.activeLayer]) {
    this.availabilityCache[this.activeLayer] = {}
  }

  const date = moment(this.date).startOf('week')
  const dateString = date.format('YYYY-MM-DD')
  const cached = this.availabilityCache[this.activeLayer][dateString]

  if (cached) {
    this.availability = cached
    return
  }

  const { data } = await this.$axios.get(
    `availabilities/layer/${this.activeLayer}/timestamp/${date.valueOf()}`,
  )
  this.availability = data
  this.availabilityCache[this.activeLayer][dateString] = data
}

export async function loadCalendar(cache = true) {
  if (this.activeLayer === null) return

  const date = moment(this.date).startOf('week')

  const dateString = date.format('YYYY-MM-DD')

  await Promise.all(
    this.layers.map(async layer => {
      if (!this.calendarCache[layer.id]) {
        this.calendarCache[layer.id] = {}
      }

      if (cache === true) {
        const cached = this.calendarCache[layer.id][dateString]
        if (cached) {
          if (layer.id === this.activeLayer) {
            this.calendar = cached
          }
          return
        }
      }

      const { data } = await this.$axios.get(
        `shift-calendar/calendar/${layer.id}/${date.valueOf()}`,
      )

      if (layer.id === this.activeLayer) {
        this.calendar = data
      }

      this.calendarCache[layer.id][dateString] = data
    }),
  )
}

export async function updateCalendar() {
  const date = moment(this.date).startOf('week')

  this.loading = true

  try {
    const { data } = await this.$axios.post(
      `shift-calendar/calendar/${this.activeLayer}/${date.valueOf()}`,
      {
        updatePool: this.updatePool,
        createPool: this.createPool,
        deletePool: this.deletePool,
      },
    )

    await this.loadCalendar(false)

    this.updatePool = []
    this.createPool = []
    this.deletePool = []

    const subject = this.$t('StaffScheduling.StaffSchedulingFor', {
      timeSpan: `${date.format('DD.MM.YYYY')} - ${date
        .endOf('week')
        .format('DD.MM.YYYY')}`,
    })

    this.calendar = data
    this.calendarCache[this.activeLayer][
      moment(this.date).startOf('week').format('YYYY-MM-DD')
    ] = data

    this.$alert.success(this.$t('Global.SuccessUpdate', { subject }))
  } catch {
    this.$alert.error()
  }

  this.loading = false
}
