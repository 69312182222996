<template>
  <div class="component">
    <select v-model="active" @change="selectedItem">
      <option v-for="layer in layers" :key="layer.id" :value="layer.id">
        {{ layer.area }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    activeLayer: {
      type: String,
      default: () => null,
    },
    layers: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    active: null,
  }),

  watch: {
    activeLayer(value) {
      this.active = value
    },
  },

  created() {
    this.active = this.activeLayer
  },

  methods: {
    selectedItem(event) {
      this.active = event.target.value
      this.$emit('change-active-layer', this.active)
    },
  },
}
</script>

<style lang="scss" scoped>
.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  position: relative;
}

select {
  text-align: center;
  text-align-last: center;
  border: none;
  background-color: transparent;
  color: orange;
  font-size: 16px;
}
</style>
