<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card no-body>
      <Layout>
        <template #header>
          <Header
            :is-admin="isAdmin"
            :employees="employees"
            :service-providers="serviceProviders"
            :date="date"
            :search-term="searchConfig.searchTerm"
            :active-layer-populated="activeLayerPopulated"
            :stack="stack"
            @open-stack="openStack"
            @change-date="changeDate"
            @change-search-term="changeSearchTerm"
          />
        </template>
        <template #side>
          <Side
            :is-admin="isAdmin"
            :date="date"
            :layers="layers"
            :search-results="searchResults"
            :service-providers="serviceProviders"
            :employees="employees"
            :can-update="canUpdate"
            :search-term="searchConfig.searchTerm"
            :work-time-adjustments="workTimeAdjustments"
            :availability="availability"
            :active-layer="activeLayer"
            :can-manage-past-attendance="canManagePastAttendance"
            :desired-count="desiredCount"
            @change-active-layer="changeActiveLayer"
            @action-drag-end="actionDragEnd"
            @action-drag-start="changeDragContext"
            @update-calendar="updateCalendar"
            @fill-rest="fillRest"
          />
        </template>
        <template #content>
          <Content
            ref="content"
            :date="date"
            :calendar="calendar"
            :calendar-cache="calendarCache"
            :active-layer-populated="activeLayerPopulated"
            :availability="availability"
            :employees="employees"
            :service-providers="serviceProviders"
            :create-pool="createPool"
            :update-pool="updatePool"
            :delete-pool="deletePool"
            :drag-context="dragContext"
            :search-term="searchConfig.searchTerm"
            :work-time-adjustments="workTimeAdjustments"
            :highlight="highlight"
            :is-admin="isAdmin"
            :absences="absences"
            @update-work-time="updateWorkTime"
            @force-update="forceUpdate"
            @pool-update="changeSearchTerm(searchConfig)"
          />
        </template>
        <template #footer>
          <Footer
            v-if="isAdmin"
            :active-layer="activeLayer"
            @transmit="toTransmit"
            @export="toExport"
            @worksheet-service-provider="openWorkSheetModal"
          />
        </template>
      </Layout>
    </b-card>
    <Stack
      v-if="isAdmin"
      ref="stack"
      :layers="layers"
      :service-providers="serviceProviders"
      :employees="employees"
      :stack="stack"
      :loading="loading"
      :can-update="canUpdate"
      :update-calendar="updateCalendar"
      @set-stack="stack = $event"
      @highlight="showHighlight"
      @reload-calendar="reloadCalendar"
    />
    <WorksheetModal ref="worksheet-modal" />
  </b-overlay>
</template>

<script>
import moment from 'moment'

import unsavedChanges from '@/utility/scripts/unsavedChanges'

import { BCard, BOverlay } from 'bootstrap-vue'
import Layout from './layout.vue'
import Content from './content/content.vue'
import Footer from './footer.vue'
import WorksheetModal from './worksheet-service-provider/worksheet-modal.vue'
import Header from './header.vue'
import Side from './side/side.vue'
import Stack from './stack/stack.vue'

import {
  initialLoading,
  load,
  loadCalendar,
  loadEmployeesAvailability,
  updateCalendar,
  loadEmployees,
  loadEmployeesDesiredCount,
  loadAbsences,
} from '../utility/load'
import { findActiveValues } from '../utility/handleDragDrop'
import { StaffSchedulingType } from '../enums'

export default {
  components: {
    BCard,
    BOverlay,
    Layout,
    Content,
    Footer,
    Header,
    Side,
    Stack,
    WorksheetModal,
  },

  data: () => ({
    isAdmin: false,

    loading: false,
    date: moment().startOf('week').valueOf(),
    searchConfig: {
      searchTerm: '',
      id: null,
      type: null,
    },
    searchResults: {},

    activeLayer: null,

    calendar: [],
    calendarCache: {},

    availability: [],
    availabilityCache: {},

    absences: [],
    absencesCache: {},

    dragContext: null,

    createPool: [],
    updatePool: [],
    deletePool: [],

    workTimeAdjustments: {},

    employees: [],
    employeesCache: {},

    desiredCount: [],
    desiredCountCache: {},

    serviceProviders: [],
    layers: [],

    stack: [],
    highlight: null,
    highlightTimeout: null,
  }),

  computed: {
    activeLayerPopulated() {
      if (this.activeLayer === null) return null
      return this.layers.find(x => x.id === this.activeLayer)
    },
    canManagePastAttendance() {
      if (this.activeLayerPopulated === null) return false
      return this.activeLayerPopulated.configuration.canManagePastAttendance
    },
    canUpdate() {
      return (
        this.createPool.length !== 0 ||
        this.updatePool.length !== 0 ||
        this.deletePool.length !== 0
      )
    },
  },

  watch: {
    canUpdate(value) {
      window.onbeforeunload = value ? () => 'confirm' : null
    },
  },

  async created() {
    this.isAdmin = this.$can(
      this.$acl.action.Update,
      this.$acl.subjects.StaffScheduling,
    )
    await this.initialLoading()
  },

  methods: {
    async reloadCalendar() {
      await this.loadCalendar(false)
      this.loadEmployees(false)
    },

    openWorkSheetModal() {
      this.$refs['worksheet-modal'].open()
    },

    async wannaLeave() {
      if (this.canUpdate) {
        return await unsavedChanges(this)
      }
      return true
    },

    async changeDate(value) {
      this.loading = true
      this.date = value

      await Promise.all([
        this.loadCalendar(),
        this.loadEmployeesAvailability(),
        this.loadEmployees(),
        this.loadEmployeesDesiredCount(),
      ])
      this.loading = false
    },

    async showHighlight(trace) {
      const date = moment(trace.staffScheduling.date, 'YYYY-MM-DD')
        .startOf('week')
        .valueOf()

      const differentLayer =
        trace.staffScheduling.layer !== this.activeLayerPopulated.id
      const differentDate = !moment(date).isSame(date, 'isoWeek')

      if (differentDate || differentLayer) {
        this.date = date
        this.activeLayer = trace.staffScheduling.layer

        this.loading = true
        await Promise.all([
          this.loadCalendar(),
          this.loadEmployeesAvailability(),
          this.loadAbsences(),
        ])
        this.loading = false

        await new Promise(r => setTimeout(() => r(), 500))
      }

      if (this.highlightTimeout !== null) {
        clearTimeout(this.highlightTimeout)
      }

      this.highlight = trace.staffScheduling.id

      this.highlightTimeout = setTimeout(() => {
        this.highlight = null
      }, 5000)
    },

    openStack() {
      this.$refs.stack.show = true
    },

    actionDragEnd() {
      this.$nextTick(() => {
        this.dragContext = null
      })
    },

    changeSearchTerm({ searchTerm, type, id }) {
      const searchResults = {}

      if (type === null) {
        this.searchConfig = {
          searchTerm: '',
          id: null,
          type: null,
        }
        this.searchResults = searchResults
        return
      }

      const date = moment(this.date).format('YYYY-MM-DD')

      this.layers.forEach(layer => {
        const calendar = this.calendarCache[layer.id][date]

        let count = 0

        calendar.forEach(layerRow => {
          layerRow.forEach(dateRow => {
            dateRow.value.forEach(staffScheduling => {
              const active = findActiveValues.call(
                this,
                dateRow,
                staffScheduling,
              )

              const member =
                type === StaffSchedulingType.Employee
                  ? 'employee'
                  : 'serviceProvider'
              if (active[member] === id) count += 1
            })
          })
        })

        if (count !== 0) {
          searchResults[layer.id] = count
        }
      })

      this.searchConfig = { searchTerm, id, type }
      this.searchResults = searchResults
    },

    findIndexInCreatePool(context, entry) {
      return this.createPool.findIndex(
        x =>
          x.layer === entry.layer &&
          x.layerIndex === context.indexConfig.layerIndex &&
          x.date === context.date &&
          x.insertedIndex === entry.insertedIndex,
      )
    },

    updateWorkTime(updateArray) {
      updateArray.forEach(x => {
        if (this.workTimeAdjustments[x.key] === undefined) {
          this.workTimeAdjustments[x.key] = x.value
        } else {
          this.workTimeAdjustments[x.key] += x.value
        }
      })

      const copy = JSON.parse(JSON.stringify(this.workTimeAdjustments))
      this.workTimeAdjustments = {}
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.workTimeAdjustments = copy
        })
      })
    },

    fillRest(serviceProvider) {
      this.$refs.content.fillRest(serviceProvider)
    },

    forceUpdate() {
      this.changeSearchTerm(this.searchConfig)
      this.calendar = JSON.parse(JSON.stringify(this.calendar))
    },

    async changeActiveLayer(value) {
      localStorage.setItem('last-active-layer', value)
      this.loading = true
      this.activeLayer = value
      await Promise.all([this.loadCalendar(), this.loadEmployeesAvailability()])
      this.loading = false
    },

    async initialLoading() {
      return initialLoading.call(this)
    },

    async load(...args) {
      return load.call(this, ...args)
    },

    async loadCalendar(...args) {
      return loadCalendar.call(this, ...args)
    },

    async loadEmployees(...args) {
      return loadEmployees.call(this, ...args)
    },

    async loadEmployeesAvailability() {
      return loadEmployeesAvailability.call(this)
    },

    async loadAbsences() {
      return loadAbsences.call(this)
    },

    async updateCalendar() {
      return updateCalendar.call(this)
    },

    async loadEmployeesDesiredCount() {
      return loadEmployeesDesiredCount.call(this)
    },

    async toExport(...args) {
      const { toExport } = await import('../utility/actions')
      return toExport.call(this, ...args)
    },

    async toTransmit(...args) {
      const { toTransmit } = await import('../utility/actions')
      return toTransmit.call(this, ...args)
    },

    changeDragContext(context) {
      this.dragContext = context
    },
  },

  async beforeRouteUpdate(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },

  async beforeRouteLeave(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },
}
</script>
