<template>
  <div v-if="active.note">
    <feather-icon
      v-b-tooltip.hover.top="active.note"
      icon="MessageSquareIcon"
      class="note-icon"
      @click="showModal = true"
    />

    <b-modal
      :visible.sync="showModal"
      size="lg"
      :title="$t('ManageShifts.Note')"
      :ok-title="$t('Global.Ok')"
      :cancel-title="$t('Global.Retreat')"
      cancel-variant="outline-secondary"
      :ok-disabled="!isAdmin"
      @ok="handleOk"
      @hide="showModal = false"
      @cancel="showModal = false"
    >
      <b-form-textarea
        v-model="note"
        rows="18"
        no-resize
        :disabled="!isAdmin"
      />
    </b-modal>
  </div>
</template>

<script>
import { BFormTextarea, BModal } from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    BModal,
  },

  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
    active: {
      type: Object,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    showModal: false,
    note: '',
  }),

  computed: {
    activeNote() {
      return this.active.note
    },
  },

  watch: {
    activeNote(value) {
      this.note = value
    },
  },

  created() {
    this.note = this.active.note
  },

  methods: {
    handleOk() {
      this.$emit('change-note', {
        context: {
          active: this.active,
          context: this.context,
          entry: this.entry,
        },
        note: this.note,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.note-icon {
  align-self: center;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  cursor: pointer;
}
</style>
