var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((Object.keys(_vm.categorizeEmployeesByContract)),function(contract){return _c('collapse-item',{key:contract,attrs:{"title":_vm.$t(("Management.Employees.Contract.Types." + contract))}},[_c('div',{staticClass:"employee-grid"},[_c('h6',[_vm._v(_vm._s(_vm.$t('StaffScheduling.Name')))]),_c('h6',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('StaffScheduling.Shifts')))])]),_c('div',{staticClass:"contract-scroll"},_vm._l((_vm.categorizeEmployeesByContract[contract]),function(workTimeCollection,workTimeCollectionIndex){
var _obj;
return _c('div',{key:workTimeCollection.key,class:{
          muted:
            _vm.availabilityGrouped(workTimeCollection.employee.id) === undefined,
        }},[_c('div',{staticClass:"employee-grid"},[_c('div',{on:{"click":function($event){return _vm.clicked(workTimeCollection.employee)}}},[_c('div',{class:{
                'active-employee':
                  workTimeCollection.employee === _vm.activeElement,
              }},[_vm._v(" "+_vm._s(workTimeCollection.employee.firstname)+" "+_vm._s(workTimeCollection.employee.lastname)+" ")])]),_c('div',{staticClass:"text-center",class:( _obj = {}, _obj[_vm.showClass(workTimeCollection.bundle, contract)] = true, _obj )},[_vm._v(" "+_vm._s(_vm.getWorkTime(workTimeCollection.bundle))+" / "+_vm._s(_vm.ContactLayersToWork[contract].value)+" ")])]),_c('div',{staticClass:"hr-wrapper"},[(
              workTimeCollectionIndex + 1 !==
              _vm.categorizeEmployeesByContract[contract].length
            )?_c('div',{staticClass:"hr"}):_vm._e()])])}),0)])}),(_vm.employeesForLayer.length === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('StaffScheduling.NoEmployees'))+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }